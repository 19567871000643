@import "https://fonts.bunny.net/css?family=chivo:400,700|noto-sans:400,700";

@import "../css/vendors/normalize.css";

@import "../css/vendors/bootstrap.min.css";

@import "../css/vendors/uicons-regular-rounded.css";

@import "../css/vendors/swiper-bundle.min.css";

@import "../css/vendors/magnific-popup.css";

@import "../css/vendors/select2.min.css";

@import "../css/vendors/perfect-scrollbar.css";

@import "../css/vendors/animate.min.css";

@import "../css/vendors/slick.css";

/* CSSTidy 2.0.3: Sun, 31 Dec 2023 15:33:09 -0600 */
@font-face {
font-family:"uicons-regular-rounded";
src:url(../fonts/uicons/uicons-regular-rounded.eot#iefix) format("embedded-opentype"),url(../fonts/uicons/uicons-regular-rounded.woff2) format("woff2"),url(../fonts/uicons/uicons-regular-rounded.woff) format("woff")
}

html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video {
border:0;
font:"heebo",sans-serif;
font-family:inherit;
font-size:100%;
font-style:normal;
font-variant:normal;
font-weight:normal;
margin:0;
padding:0;
scroll-behavior:smooth;
vertical-align:baseline
}

main {
clear:both;
display:block
}

thead {
font-weight:600
}

article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section {
display:block
}

body {
background:#fff;
background-size:contain;
line-height:1
}

ol,ul {
list-style:none
}

blockquote,q {
quotes:none
}

blockquote:before,blockquote:after,q:before,q:after {
content:""
}

table {
border-collapse:collapse;
border-spacing:0
}

input,select,button,textarea {
font:14px "Chivo",sans-serif
}

:focus,select:focus,.custom-select:focus,button:focus,textarea:focus,textarea.form-control:focus,input.form-control:focus,input[type=text]:focus,input[type=password]:focus,input[type=email]:focus,input[type=number]:focus,[type=text].form-control:focus,[type=password].form-control:focus,[type=email].form-control:focus,[type=tel].form-control:focus,[contenteditable].form-control:focus {
box-shadow:none!important;
outline:none!important
}

input:focus::-moz-placeholder {
opacity:0;
transition:.4s
}

li.hr span {
background-color:#e4e4e4;
display:block;
height:1px;
margin:20px 0;
width:100%
}

::-moz-selection {
background:#3F57A7;
background:#3F57A7;
color:#fff
}

::selection {
background:#3F57A7;
color:#fff
}

.clear {
clear:both
}

.section {
float:left;
width:100%
}

.f-right {
float:right
}

.capitalize {
text-transform:capitalize
}

.uppercase {
text-transform:uppercase
}

.bg-img {
background-position:center center;
background-size:cover
}

.position-relative {
position:relative
}

.height-100vh {
height:100vh!important
}

.border-radius {
border-radius:4px
}

.border-radius-5 {
border-radius:5px
}

.border-radius-10 {
border-radius:10px
}

.border-radius-15 {
border-radius:15px
}

.border-radius-20 {
border-radius:20px
}

.hover-up {
transition:all .25s cubic-bezier(0.02,0.01,0.47,1)
}

.hover-up:hover {
transform:translateY(-3px);
transition:all .25s cubic-bezier(0.02,0.01,0.47,1)
}

a {
color:#3F57A7;
text-decoration:none
}

:hover {
transition-duration:.2s
}

.text-body-lead {
font:700 16px/24px "Noto Sans",sans-serif
}

.text-body-excerpt {
font:400 18px/26px "Noto Sans",sans-serif
}

.text-body-text {
font:400 16px/28px "Noto Sans",sans-serif!important
}

.text-body-quote {
font:600 20px/28px "Noto Sans",sans-serif
}

.text-body-normal {
font:400 20px/28px "Noto Sans",sans-serif
}

.text-body-capitalized {
font:400 14px/19px "Noto Sans",sans-serif;
letter-spacing:2px
}

.text-body-small {
font:500 13px/16px "Noto Sans",sans-serif
}

.text-body-tiny {
font:600 10px/14px "Noto Sans",sans-serif
}

.text-body-lead-large {
font:400 24px/32px "Noto Sans",sans-serif
}

.text-body-text-md {
font:400 14px/19px "Noto Sans",sans-serif
}

.tablet-display-2 {
font:700 72px/82px "Chivo",sans-serif;
letter-spacing:-.5%
}

.text-display-1 {
font:900 96px/132px "Chivo",sans-serif;
letter-spacing:-1%
}

.text-display-2 {
font:700 80px/88px "Chivo",sans-serif;
letter-spacing:-.5%
}

.text-display-3 {
font:700 64px/72px "Chivo",sans-serif;
letter-spacing:0
}

.text-display-4 {
font:400 56px/66px "Chivo",sans-serif;
letter-spacing:0
}

.text-heading-1 {
color:#101828;
font:700 56px/64px "Chivo",sans-serif;
letter-spacing:-1%
}

.text-heading-2 {
font:700 42px/44px "Chivo",sans-serif;
letter-spacing:0
}

.text-heading-3 {
font:700 35px/38px "Chivo",sans-serif;
letter-spacing:0
}

.text-heading-4 {
font:700 28px/32px "Chivo",sans-serif;
letter-spacing:0
}

.text-heading-5 {
font:700 22px/24px "Chivo",sans-serif;
letter-spacing:0
}

.text-heading-6 {
font:700 18px/22px "Chivo",sans-serif;
letter-spacing:0
}

.text-bold {
font:700 14px/14px "Noto Sans",sans-serif
}

.color-orange-100 {
color:#FAEDE3
}

.color-orange-500 {
color:#FFDDD2
}

.color-orange-900 {
color:#E29578
}

.color-green-900 {
color:#3F57A7!important
}

.color-green-100 {
color:#AFEAE4
}

.color-gray-900 {
color:#101828!important
}

.color-gray-1000 {
color:#1F2938
}

.color-gray-1100 {
color:#7E7E7E
}

.color-gray-1200 {
color:#9A9A9A
}

.color-gray-500 {
color:#667085
}

.color-gray-400 {
color:#98A2B3!important
}

.color-gray-300 {
color:#D0D5DD!important
}

.color-gray-600 {
color:#475467
}

.color-gray-100 {
color:#F2F4F7
}

.color-white {
color:#fff!important
}

.bg-1 {
background-color:#EAE4E9!important
}

.bg-2 {
background-color:#fff!important
}

.bg-2-opacity-80 {
background-color:#fff3eacc
}

.bg-3 {
background-color:#FDE2E4!important
}

.bg-6 {
background-color:#87CEEB!important
}

.bg-6-opacity-30 {
background-color:#bee1e64d!important
}

.bg-7 {
background-color:#F0EFEB!important
}

.bg-8 {
background-color:#DFE7FD!important
}

.bg-9 {
background-color:#D1ECFD!important
}

.bg-10 {
background-color:#DDD3FA!important
}

.bg-gray-100 {
background-color:#F2F4F7!important
}

.text-billed {
color:#fff;
display:inline-block;
font:400 16px/28px "Noto Sans",sans-serif;
vertical-align:middle
}

.section-box {
display:inline-block;
width:100%
}

.box-shadow-4 {
box-shadow:4px 6px 23px #e45c231a
}

.content-detail {
color:#101828
}

.content-detail h2 {
margin:0 0 20px
}

.content-detail p {
color:#101828;
font:16px/28px "Noto Sans",sans-serif;
margin-bottom:15px
}

.content-detail ul {
color:#101828;
font:16px/28px "Noto Sans",sans-serif;
list-style:disc;
padding-left:20px
}

.border-bottom {
border-bottom:1px solid #061224;
margin:30px 0;
width:100%
}

.img-middle {
display:inline-block;
margin-top:-2px;
vertical-align:middle
}

.box-shadow-2 {
box-shadow:0 45px 80px #0000000a
}

.box-line-throught {
display:inline-block;
padding:0 60px;
position:relative
}

.box-line-throught::before {
background-color:#D0D5DD;
content:"";
height:1px;
left:0;
position:absolute;
top:50%;
transform:translateY(-50%);
width:50px
}

.box-line-throught::after {
background-color:#D0D5DD;
content:"";
height:1px;
position:absolute;
right:0;
top:50%;
transform:translateY(-50%);
width:50px
}

.img-full {
width:100%
}

.thw-20 {
width:20%
}

.thw-40 {
width:40%
}

.br-16 {
border-radius:16px
}

a,button,img,input,span,h4 {
transition:all .3s ease 0s
}

.hieu-test,.hidden {
display:none
}

img,.img-responsive {
max-width:100%
}

::-moz-placeholder,::placeholder,:-ms-input-placeholder,::-webkit-input-placeholder {
color:#667085;
opacity:1
}

.fix,.tab-content > .active {
overflow:hidden
}

.color-green-500,a:hover {
color:#83C5BE
}

.bg-4,.bg-5 {
background-color:#FFF!important
}

@keyframes slideleft {
10% {
opacity:0;
right:0;
transform:scale(0)
}

50% {
opacity:1;
transform:scale(1)
}

90% {
opacity:0;
right:100%;
transform:scale(0)
}
}

[data-loader=spinner] {
animation:spinner 1.2s infinite ease-in-out;
background:url(../imgs/brand/favicon.ico);
box-shadow:0 0 10px #fff;
display:inline-block;
height:35px;
width:35px
}

@keyframes spinner {
0% {
transform:perspective(120px) rotateX(0) rotateY(0)
}

50% {
transform:perspective(120px) rotateX(-180deg) rotateY(0)
}

100% {
transform:perspective(120px) rotateX(-180deg) rotateY(-180deg)
}
}

@keyframes shadow-pulse {
0% {
box-shadow:0 0 0 0 #9777facc
}

100% {
box-shadow:0 0 0 5px #0000
}
}

@keyframes shadow-pulse-big {
0% {
box-shadow:0 0 0 0 #ef3f481a
}

100% {
box-shadow:0 0 0 20px #0000
}
}

@keyframes jump {
0% {
transform:translate3d(0,20%,0)
}

100% {
transform:translate3d(0,0,0)
}
}

.jump {
animation:jump .5s linear alternate infinite;
transform-origin:0
}

body {
color:#101828;
font:400 14px/24px "Chivo",sans-serif
}

h1,h2,h3,h4,h5,h6,.font-heading,.display-1,.display-2,.heading-lg,.heading-sm-1 {
color:#101828;
font-family:"Noto Sans",sans-serif;
font-weight:700;
line-height:1.2
}

h1 {
font-size:69px;
line-height:1.2
}

h2 {
font-size:55px;
line-height:67px
}

h3 {
font-size:44px;
line-height:54px
}

h4 {
font-size:28px;
line-height:34px
}

h5 {
font-size:22px;
font-weight:500;
line-height:28px
}

h6 {
font-size:16px;
font-weight:500;
line-height:22px
}

.heading-lg {
font-size:52px;
font-weight:700;
line-height:63px
}

.heading-md {
color:#101828;
font:500 20px/24px "Noto Sans",sans-serif
}

.heading-sm {
font-size:14px;
font-weight:500;
line-height:20px
}

.text-sm {
font:12px/16px "Chivo",sans-serif
}

.text-md {
font:16px/20px "Chivo",sans-serif
}

.small-heading {
font:700 16px/20px "Noto Sans",sans-serif
}

.medium-heading {
color:#101828;
font:600 18px/22px "Noto Sans",sans-serif
}

.font-bold {
font-weight:700
}

.font-semibold {
font-weight:600
}

.font-3xs {
font-size:10px
}

.font-xxs {
font-size:12px
}

.font-xs {
font-size:13px
}

.font-sm {
font-size:14px
}

.font-md {
font-size:16px
}

.font-md-2 {
font-size:15px
}

.font-lg {
font-size:18px
}

.font-xl {
font-size:20px
}

.font-2xl {
font-size:22px
}

.font-3xl {
font-size:58px
}

.text-grey-6 {
color:#101828!important
}

.text-muted {
color:#667085!important
}

.btn {
border-radius:50px;
font:18px/22px "Chivo",sans-serif;
letter-spacing:.45px;
padding:22px 32px;
transition:.2s
}

.btn.btn-square {
background-color:#101828;
border:2px solid transparent;
border-radius:4px;
color:#fff;
font-size:14px;
font-weight:700;
line-height:14px;
padding:18px 20px
}

.btn.btn-square:hover {
background-color:#fff;
border-color:#101828;
color:#101828
}

.btn.btn-black-border {
border:1px solid #101828;
border-radius:8px;
padding:12px 22px;
text-align:left;
width:100%
}

.btn.btn-black-border:hover {
background-color:#101828;
background-image:url(../imgs/template/icons/arrow-small-right-w.svg);
background-repeat:no-repeat;
color:#fff;
transform:translateY(-2px);
transition:.2s
}

.btn.btn-default {
background-color:#F2F4F7;
border-radius:50px;
color:#101828;
font:700 18px/22px "Chivo",sans-serif
}

.btn.btn-bd-green-hover {
border:2px solid transparent;
color:#475467
}

.btn.btn-select {
margin-bottom:15px;
margin-left:10px;
margin-right:10px
}

.btn.btn-green-900 {
background-color:#3F57A7;
color:#fff;
font:700 18px/22px "Chivo",sans-serif;
letter-spacing:0
}

.btn.btn-black {
background-color:#101828;
border-radius:50px;
color:#fff;
font:700 18px/22px "Noto Sans",sans-serif
}

.btn.btn-black.icon-arrow-right-white:hover {
background-color:#F2F4F7;
background-image:url(../imgs/template/icons/fi-sr-arrow-small-right.svg);
background-repeat:no-repeat;
color:#101828;
transform:translateY(-2px);
transition:.2s
}

.btn.btn-black:hover {
background-color:#E4E7EC;
color:#101828
}

.btn.btn-md {
padding:16px 20px
}

.btn.shape-square {
border-radius:4px
}

.btn.btn-link {
color:#101828;
font:18px/18px "Chivo",sans-serif;
text-decoration:none
}

.btn.btn-pink {
background-color:#E29578;
border-radius:4px;
color:#fff
}

.btn.btn-link-inter {
font:500 20px/24px "Chivo",sans-serif!important
}

.btn.btn-round-icon {
background-image:url(../imgs/template/icons/btn-send.svg);
background-position:-30px -21px;
background-repeat:no-repeat;
border-radius:0;
display:inline-block;
height:57px;
padding:0;
width:79px
}

.btn.btn-square-green {
background-color:#3F57A7;
border-radius:8px;
color:#fff;
height:48px;
padding:1px 22px;
width:120px
}

.btn.btn-green-full {
background-color:#3F57A7;
border-radius:4px;
color:#fff;
height:48px;
padding:1px 22px;
width:100%
}

.btn.btn-media {
border:1px solid #0612241a;
border-radius:4px;
line-height:32px;
min-height:36px;
padding:1px 16px 3px
}

.btn.btn-media img {
display:inline-block;
margin-right:5px;
vertical-align:middle
}

.btn.btn-login-google {
background-color:#fff;
border-radius:8px;
display:block;
padding:13px 25px
}

.btn.btn-tag {
background-color:#E4E7EC;
border-radius:8px;
color:#101828;
font:400 14px/18px "Noto Sans",sans-serif;
padding:12px 20px
}

.btn.btn-tag:hover {
background-color:#101828;
color:#fff
}

.btn.btn-newsletter {
background-color:#3F57A7;
background-image:url(../imgs/template/icons/arrow-small-right-w.svg);
background-position:center right 25px;
background-repeat:no-repeat;
background-size:15px;
border:0;
border-radius:8px;
color:#fff;
font:700 18px/22px "Chivo",sans-serif;
padding:16px 56px 16px 27px;
position:absolute;
right:10px;
top:10px
}

.btn.btn-cart {
background-color:#AFEAE4;
background-position:center right 10px;
background-repeat:no-repeat;
border-radius:8px;
color:#3F57A7;
font:700 16px/24px "Noto Sans",sans-serif;
padding:5px 25px 5px 15px
}

.btn.btn-explorer {
background-color:#AFEAE4;
background-position:center right 12px;
background-repeat:no-repeat;
border-radius:8px;
color:#3F57A7;
font:700 16px/24px "Noto Sans",sans-serif;
padding:5px 32px 5px 15px
}

.btn.btn-border {
border:1px solid #E4E7EC;
padding:13px 20px
}

.btn.btn-border:hover {
border-color:#004B52;
color:#004B52
}

.icon-arrow-right {
background-image:url(../imgs/template/icons/fi-sr-arrow-small-right.svg);
background-position:center right 25px;
background-repeat:no-repeat;
padding-right:56px
}

.icon-arrow-left {
background-image:url(../imgs/template/icons/arrow-small-left-white.svg);
background-position:center left 25px;
background-repeat:no-repeat;
padding-left:56px
}

.icon-arrow-left:hover {
background-image:url(../imgs/template/icons/arrow-small-left.svg)!important
}

.icon-arrow-right-white {
background-image:url(../imgs/template/icons/arrow-small-right-w.svg);
background-position:center right 25px;
background-repeat:no-repeat;
padding-right:56px
}

.icon-triangle {
background-image:url(../imgs/template/icons/icon-triangle.svg);
background-position:center left;
background-repeat:no-repeat;
font:500 20px/24px "Chivo",sans-serif!important;
padding-left:30px
}

.btn-play-video {
background:url(../imgs/template/icons/play-button.svg) no-repeat;
height:72px;
left:-34px;
margin-top:-30px;
position:absolute;
top:50%;
width:64px
}

.switch {
display:inline-block;
height:30px;
position:relative;
vertical-align:middle;
width:65px
}

.switch input {
height:0;
opacity:0;
width:0
}

.slider {
background-color:#F2F4F7;
bottom:0;
cursor:pointer;
left:0;
position:absolute;
right:0;
top:0;
transition:.4s
}

.slider:before {
background-color:#101828;
bottom:5px;
content:"";
height:21px;
left:5px;
position:absolute;
transition:.4s;
width:21px
}

input:focus + .slider {
box-shadow:0 0 1px #101828
}

input:checked + .slider:before {
transform:translateX(34px)
}

.slider.round {
border-radius:34px
}

.slider.round:before {
border-radius:50%
}

span.btn {
cursor:unset
}

.cb-container {
cursor:pointer;
display:block;
line-height:21px;
margin-bottom:12px;
padding-left:35px;
position:relative
}

.cb-container input {
cursor:pointer;
height:0;
opacity:0;
position:absolute;
width:0
}

.checkmark {
background-color:#fff;
border:2px solid #101828;
border-radius:4px;
height:23px;
left:0;
position:absolute;
top:0;
width:23px
}

.cb-container input:checked ~ .checkmark {
border:2px solid #101828
}

.checkmark:after {
content:"";
display:none;
position:absolute
}

.cb-container .checkmark:after {
height:21px;
left:-1px;
top:-1px;
width:21px
}

.form-control {
background:#fff;
border:0;
border-radius:0;
box-shadow:none;
color:#98A2B3;
font-size:14px;
height:60px;
line-height:20px;
padding-left:30px;
padding-right:30px;
width:100%
}

textarea.form-control {
background:#fff;
border:0;
border-radius:0;
box-shadow:none;
color:#98A2B3;
font-size:14px;
line-height:20px;
min-height:160px;
padding:30px;
width:100%
}

.form-group {
margin-bottom:25px
}

.form-group input {
background:#fff;
border:0;
border-radius:0;
box-shadow:none;
font-size:14px;
height:60px;
line-height:20px;
padding-left:30px;
padding-right:30px;
width:100%
}

label {
margin-bottom:5px
}

.form-round {
background:#fff;
border-radius:55px;
margin:15px auto;
max-width:500px;
padding:10px 12px
}

.input-round {
border-radius:55px;
outline:0
}

.form-square {
background:#fff;
border-radius:15px;
margin:15px auto;
max-width:100%;
padding:10px 12px;
position:relative
}

.input-with-button {
background:url(../imgs/template/icons/icon-search-input.svg) no-repeat 7px 17px;
border-radius:15px;
outline:0;
padding-left:50px
}

.input-with-button:focus {
background:url(../imgs/template/icons/icon-search-input.svg) no-repeat 7px 17px;
border-color:#1D2939
}

.box-signup {
display:table;
margin:auto;
max-width:405px;
table-layout:fixed;
width:100%
}

.box-signup .box-form-signup {
background-color:#fff;
border-radius:6px;
box-shadow:0 47px 65px #151c261a;
padding:45px
}

.box-signup .chkbox {
float:left;
height:18px;
margin:-1px 10px 0 0;
width:18px
}

.box-signup .form-group input.form-control {
background:#fff;
border:1px solid #eee;
border-radius:4px;
box-shadow:none;
color:#101828;
font-size:14px;
height:56px;
line-height:20px;
padding-left:20px;
padding-right:20px;
width:100%
}

.box-signup .form-group input.input-green-bd {
border:2px solid #3F57A7
}

.box-signup .form-group input.input-with-icon {
padding-right:40px
}

.box-signup .form-field .tag-top {
background-color:#fff;
left:10px;
padding:0 5px;
position:absolute;
top:-7px
}

.box-signup .form-field .icon-eye-right {
background:url(../imgs/template/icons/icon-eye.svg) no-repeat center;
cursor:pointer;
height:24px;
position:absolute;
right:14px;
top:50%;
transform:translateY(-50%);
width:25px
}

.box-signup .form-field .icon-email-right {
background:url(../imgs/template/icons/icon-email-2.svg) no-repeat center;
cursor:pointer;
height:24px;
position:absolute;
right:14px;
top:50%;
transform:translateY(-50%);
width:25px
}

.box-reset .box-form-signup {
border:1px solid #E4E7EC
}

.form-comment .input-comment {
border:2px solid #E4E7EC;
border-radius:16px;
color:#98A2B3;
font:400 20px/27px "Noto Sans",sans-serif;
min-height:150px;
padding:20px;
width:100%
}

.form-comment .box-agree span {
display:block;
padding-left:25px
}

.form-comment .box-agree input {
float:left;
margin:5px 0 0
}

.box-swiper .swiper-container .item-logo {
border:1px solid #667085;
border-radius:12px;
box-shadow:0 45px 80px #0000000a;
display:inline-block;
padding:39px 0 36px;
text-align:center;
width:100%
}

.box-swiper .swiper-container .item-logo img {
max-width:100%
}

.box-swiper .swiper-container .item-logo:hover {
border:1px solid #1D2939;
box-shadow:0 47px 65px #151c261a
}

.box-swiper .swiper-button-next:after,.box-swiper .swiper-button-prev:after {
content:""
}

.box-swiper .swiper-button-next {
background:url(../imgs/slider/swiper/next.svg) no-repeat 0 0;
background:url(../imgs/slider/swiper/next.svg) no-repeat 0 0;
left:auto;
right:0;
top:-65px
}

.box-swiper .swiper-button-next:hover {
background:url(../imgs/slider/swiper/next-active.svg) no-repeat 0 0;
background:url(../imgs/slider/swiper/next-active.svg) no-repeat 0 0
}

.box-swiper .swiper-button-prev {
background:url(../imgs/slider/swiper/prev.svg) no-repeat 0 0;
background:url(../imgs/slider/swiper/prev.svg) no-repeat 0 0;
left:auto;
right:84px;
top:-65px
}

.box-swiper .swiper-button-prev:hover {
background:url(../imgs/slider/swiper/prev-active.svg) no-repeat 0 0;
background:url(../imgs/slider/swiper/prev-active.svg) no-repeat 0 0
}

.box-swiper .swiper-button-next,.box-swiper .swiper-button-prev {
height:64px;
margin-top:-55px;
width:64px
}

.swiper-pagination {
text-align:center;
width:100%
}

.swiper-pagination-bullet {
margin:10px
}

.swiper-pagination-custom {
bottom:-5px
}

.swiper-pagination-customs {
background:url(../imgs/slider/swiper/dot.svg) no-repeat 0 0;
background:url(../imgs/slider/swiper/dot.svg) no-repeat 0 0;
background-repeat:no-repeat;
background-size:contain;
display:inline-block;
height:10px;
margin-left:4px;
margin-right:4px;
width:10px
}

.swiper-pagination-customs-active,.swiper-pagination-customs:hover {
background:url(../imgs/slider/swiper/dot-active.svg) no-repeat 0 0;
background:url(../imgs/slider/swiper/dot-active.svg) no-repeat 0 0;
height:12px;
margin-bottom:-1px;
margin-left:3px;
margin-right:3px;
width:12px
}

.slider-labels {
margin-top:10px
}

.noUi-target,.noUi-target * {
box-sizing:border-box
}

.noUi-target {
border-radius:2px;
direction:ltr;
position:relative
}

.noUi-base {
height:100%;
position:relative;
width:100%;
z-index:1
}

.noUi-origin {
border-radius:2px;
bottom:0;
left:0;
position:absolute;
right:0;
top:0
}

.noUi-handle {
box-sizing:content-box!important;
cursor:default;
position:relative;
z-index:1
}

.noUi-stacking .noUi-handle {
z-index:10
}

.noUi-state-tap .noUi-origin {
transition:left 0.3s,top .3s
}

.noUi-state-drag * {
cursor:inherit!important
}

.noUi-base,.noUi-handle {
transform:translate3d(0,0,0)
}

.noUi-horizontal {
height:4px
}

.noUi-horizontal .noUi-handle {
background-color:#345DBB;
border:1px solid #3F57A7;
border-radius:50%;
box-shadow:0 47px 65px #151c261a;
height:18px;
left:-7px;
top:-7px;
width:18px
}

.noUi-background {
background:#D6D7D9;
background:#E4E7EC
}

.noUi-connect {
background:#345DBB;
background:#3F57A7;
height:6px;
margin-top:0;
transition:background 450ms
}

.noUi-draggable {
cursor:w-resize
}

.noUi-vertical .noUi-draggable {
cursor:n-resize
}

.noUi-handle:active {
background-clip:padding-box;
border:8px solid #345DBB;
border-width:1px;
left:-14px;
top:-14px
}

[disabled].noUi-connect,[disabled] .noUi-connect {
background:#3F57A7
}

[disabled].noUi-origin,[disabled] .noUi-handle {
cursor:not-allowed
}

.ui-widget.ui-widget-content {
border:0 solid #EBEBEB
}

.card-grid-1 {
border-radius:16px;
display:inline-block;
margin-bottom:20px;
padding:53px 48px;
width:100%
}

.card-grid-style-2 {
background:#fff;
border-radius:16px;
box-shadow:inset 0 40px 80px #FCFCFC;
display:inline-block;
padding:53px 48px;
position:relative;
width:100%
}

.card-grid-style-3 {
background:#fff;
border:10px solid #3F57A7;
border-radius:0;
display:inline-block;
height:100%;
margin-bottom:30px;
padding:40px 40px 10px;
position:relative;
width:100%
}

.card-grid-style-3 .grid-3-img img {
border-radius:50%;
height:55px;
width:55px
}

.card-grid-style-3 .text-desc {
height:115px;
overflow:hidden
}

.card-grid-style-3.bd-bg-10 {
border-color:#DDD3FA
}

.card-grid-style-3.bd-bg-9 {
border-color:#D1ECFD
}

.card-grid-style-4 {
margin-bottom:30px
}

.card-grid-style-4 a.text-heading-4 {
color:#101828;
display:block;
padding:15px 0 25px
}

.card-grid-style-4 .grid-4-img {
padding-bottom:15px;
position:relative
}

.card-grid-style-4 .grid-4-img img {
border-radius:16px;
max-width:100%;
position:relative;
transition:.2s;
width:100%;
z-index:2
}

.card-grid-style-4 .grid-4-img::before {
background-color:#D1ECFD;
border-radius:16px;
bottom:0;
content:"";
position:absolute;
right:-20px;
top:20px;
transition-duration:.2s;
width:100%
}

.card-grid-style-4:hover .grid-4-img::before {
bottom:10px;
right:-10px;
top:10px;
transition-duration:.2s
}

.card-grid-style-4:hover .grid-4-img img {
opacity:.95;
transition:.2s
}

.card-grid-style-5 {
margin-bottom:50px
}

.card-grid-style-6 {
background:#FFF3EA;
border-radius:16px;
display:inline-block;
margin-bottom:30px;
padding:53px 48px;
position:relative;
width:100%
}

.block-pricing .box-pricing-item {
background:#fff;
border-radius:16px;
display:inline-block;
margin-bottom:30px;
padding:40px 32px;
width:100%
}

.block-pricing .box-pricing-item .line-bd-bottom {
border-bottom:1px solid #D0D5DD;
margin-bottom:21px;
padding:0 0 40px
}

.block-pricing .box-pricing-item .btn-border {
border:1px solid #475467;
color:#101828
}

.block-pricing .box-info-price {
display:inline-block;
padding-bottom:20px;
width:100%
}

.block-pricing .box-info-price .text-price {
color:#101828;
font:600 36px/46px "Noto Sans",sans-serif;
margin-right:15px
}

.block-pricing .box-info-price .text-month {
color:#98A2B3;
font-size:17px;
line-height:23px
}

.block-pricing .list-package-feature {
display:inline-block;
min-height:200px;
padding-bottom:30px;
width:100%
}

.block-pricing .list-package-feature li {
background:url(../imgs/template/icons/check-circle.svg) no-repeat left center;
color:#101828;
display:inline-block;
font-size:14px;
line-height:22px;
margin-bottom:12px;
padding:1px 0 1px 30px;
width:100%
}

.block-pricing .list-package-feature li.uncheck {
background:url(../imgs/template/icons/un-check-circle.svg) no-repeat left center
}

.block-pricing-2 .box-pricing-item {
border:1px solid #D0D5DD
}

.block-pricing-2 .box-pricing-item.active,.block-pricing-2 .box-pricing-item:hover {
background-color:#3F57A7;
border:none
}

.block-pricing-3 {
background-color:#fff;
border-radius:16px;
box-shadow:14px 14px 36px #99999938;
padding:40px
}

.block-pricing-3 .block-pricing-left {
background-color:#3F57A7;
border-radius:16px;
color:#fff;
padding:40px
}

.block-pricing-3 .block-pricing-left .box-switch {
background-color:#83C5BE;
border-radius:28px;
display:inline-block;
margin-bottom:20px;
padding:7px
}

.block-pricing-3 .block-pricing-left .box-switch label {
margin:0
}

.block-pricing-3 .block-pricing-left .box-switch span {
cursor:pointer;
display:inline-block;
min-width:100px;
padding:7px 22px;
text-align:center
}

.block-pricing-3 .block-pricing-left .box-switch .active {
background-color:#3F57A7;
border-radius:24px
}

.block-pricing-3 .block-pricing-left .list-package-feature li {
background:url(../imgs/template/icons/icon-untick.svg) no-repeat right 15px top 1px;
color:#fff;
display:inline-block;
font:400 16px/28px "Noto Sans",sans-serif;
margin-bottom:22px;
margin-top:22px;
padding:2px 30px 2px 0;
width:100%
}

.block-pricing-3 .block-pricing-left .list-package-feature li.active {
background:url(../imgs/template/icons/icon-tick.svg) no-repeat right 15px top 1px
}

.block-pricing-3 .block-price-item {
border:1px solid #98A2B3;
border-radius:16px;
cursor:pointer;
margin-bottom:24px;
padding:22px 120px 21px 100px;
position:relative
}

.block-pricing-3 .block-price-item .checkbox {
background:url(../imgs/template/icons/icon-unselect.svg) no-repeat 0 0;
height:30px;
left:35px;
position:absolute;
top:50%;
transform:translateY(-50%);
width:30px
}

.block-pricing-3 .block-price-item .box-info-price {
position:absolute;
right:15px;
top:50%;
transform:translateY(-50%)
}

.block-pricing-3 .block-price-item .tag-round {
background-color:#667085;
border-radius:14px;
color:#fff;
display:inline-block;
padding:2px 15px
}

.card-list-style-1 {
border-bottom:1px solid #E4E7EC;
display:inline-block;
margin-bottom:30px;
padding:0 140px 30px 0;
position:relative;
width:100%
}

.card-list-style-1 .style-1-img {
padding:0 10px 10px 0;
position:absolute;
right:0;
top:0;
width:120px
}

.card-list-style-1 .style-1-img img {
border-radius:8px;
position:relative;
z-index:2
}

.card-list-style-1 .style-1-img::before {
background-color:#D1ECFD;
border-radius:8px;
bottom:5px;
content:"";
position:absolute;
right:-5px;
top:10px;
transition-duration:.2s;
width:calc(100% - 10px);
z-index:1
}

.card-list-style-1:hover .style-1-img::before {
bottom:10px;
right:0;
transition-duration:.2s
}

.grid-category-2 {
display:inline-block;
margin-bottom:60px;
position:relative;
width:100%
}

.grid-category-2 .grid-category-image {
margin:0 0 20px
}

.grid-category-2 .category-info-bottom {
border-top:1px solid #E4E7EC;
display:flex;
margin:20px 0 0;
padding-top:30px
}

.grid-category-2 .category-info-bottom * {
color:#3F57A7;
font:700 12px/16px "Noto Sans",sans-serif;
text-decoration:none
}

.grid-category-2 .category-info-bottom .link-category {
width:75%
}

.grid-category-2 .category-info-bottom .link-readmore {
text-align:right;
width:25%
}

.grid-category-2 .category-info-bottom .link-readmore a {
text-decoration:underline
}

.product-item-1 {
border:1px solid #E4E7EC;
border-radius:16px;
display:inline-block;
margin-bottom:30px;
padding:10px;
position:relative;
width:100%
}

.product-item-1 .product-image {
float:left;
margin:0;
padding:5px 0 0;
text-align:center;
width:150px
}

.product-item-1 .product-image img {
max-height:150px
}

.product-item-1 .product-info {
display:block;
padding:20px 0 20px 160px
}

.product-item-1 .product-info .title-product {
color:#101828;
font:26px/32px "Chivo",sans-serif;
margin-bottom:10px
}

.product-item-1 .product-info .price-regular {
color:#101828;
font:28px/34px "Chivo",sans-serif
}

.product-item-1 .product-info .price-line {
color:#667085;
text-decoration:line-through
}

.product-item-2 {
background-color:#fff;
border:1px solid #E4E7EC;
border-radius:16px;
display:inline-block;
margin-bottom:30px;
padding:10px;
position:relative;
width:100%
}

.product-item-2:hover .box-quick-view {
display:block;
opacity:1;
transition-duration:.2s;
visibility:visible
}

.product-item-2 .box-quick-view {
display:none;
opacity:0;
transition-duration:.2s;
visibility:hidden
}

.product-item-2 .box-quick-view .quick-view {
border:1px solid #D0D5DD;
border-radius:8px;
display:flex;
left:0;
margin:auto;
overflow:hidden;
position:absolute;
right:0;
top:110px;
width:150px
}

.product-item-2 .box-quick-view .quick-view .shuffle-product {
border-left:1px solid #E4E7EC;
border-right:1px solid #E4E7EC;
display:inline-block;
height:46px;
padding:10px;
width:33.33%
}

.product-item-2 .product-image {
float:left;
height:220px;
line-height:220px;
margin:0 0 20px;
min-height:220px;
padding:5px 0 0;
position:relative;
text-align:center;
width:100%
}

.product-item-2 .product-image img {
display:inline-block;
height:auto;
max-height:100%;
max-width:80%;
vertical-align:middle;
width:auto
}

.product-item-2 .product-info {
display:block;
padding:20px 0
}

.product-item-2 .product-info a:hover h3 {
color:#3F57A7!important
}

.product-item-2 .product-info .box-prices .price-regular {
color:#3F57A7;
font:700 22px/24px "Noto Sans",sans-serif
}

.product-item-2 .product-info .box-prices .price-line {
color:#98A2B3;
font-size:14px;
line-height:19px;
text-decoration:line-through
}

.product-item-2 .product-info .rating .box-rating {
display:inline-block;
margin-right:5px
}

.product-item-2 .product-info .rating .box-rating span {
display:inline-block;
vertical-align:top
}

.product-item-3 {
margin-bottom:40px;
padding:25px
}

.product-item-3 .product-image {
height:auto;
line-height:normal;
margin-bottom:30px;
min-height:auto;
padding-top:0
}

.product-item-3 .product-image img {
border-radius:16px;
max-width:100%;
width:100%
}

.product-item-3 .product-info {
padding-bottom:0
}

.product-item-3 .location-icon,.product-item-3 .calendar-icon {
color:#667085;
display:inline-block;
font:700 12px/16px "Noto Sans",sans-serif;
padding:0 0 0 16px;
text-decoration:none
}

.nav-right {
float:right;
margin-top:-10px
}

.nav-right li {
float:left;
padding:0 0 0 5px
}

.nav-right li button {
background:transparent;
border:0;
border-radius:8px;
color:#101828;
display:block;
font:500 16px "Noto Sans",sans-serif;
padding:10px 15px;
text-decoration:none;
transition-duration:.2s
}

.nav-right li button:hover {
background-color:#FFF3EA;
color:#fff;
transform:translateY(-3px);
transition-duration:.2s
}

.nav-right li button.active {
background-color:#FFF3EA;
color:#fff
}

.box-newsletter {
border-radius:18px;
padding:52px 74px
}

.box-newsletter .box-form-newsletter {
background:#fff;
border-radius:55px;
max-width:500px;
padding:11px 12px
}

.box-newsletter .box-form-newsletter form .input-newsletter {
border:0;
color:#98A2B3;
font:400 16px/28px "Noto Sans";
min-height:50px;
padding:15px;
width:90%
}

.box-newsletter .box-form-newsletter form .font-heading {
font-family:"Noto Sans",sans-serif;
font-weight:500
}

.box-newsletter .btn-send {
background-image:url(../imgs/template/icons/btn-send.svg);
background-position:-30px -21px;
background-repeat:no-repeat;
border-radius:0;
display:inline-block;
height:57px;
padding:0;
width:72px
}

.box-newsletter .block-chart {
bottom:0;
height:194px;
left:0;
max-width:255px;
position:absolute;
z-index:2
}

.box-newsletter .block-chart img {
border-radius:16px;
box-shadow:0 47px 65px #151c261a
}

.box-newsletter .img-newsletter {
border-radius:16px;
bottom:-110px;
box-shadow:0 47px 65px #151c261a;
display:inline-block;
max-width:332px;
position:absolute;
right:0;
z-index:1
}

.preloader {
background-color:#f7f7f7;
bottom:0;
height:100%;
left:0;
margin:0 auto;
position:fixed;
right:0;
top:0;
transition:.6s;
width:100%;
z-index:999999
}

.preloader img {
max-width:250px
}

.rate {
display:flex;
height:21px;
justify-content:center;
padding:0 10px
}

.rate:not(:checked) > input {
position:absolute;
visibility:hidden
}

.rate:not(:checked) > label {
color:#ccc;
cursor:pointer;
float:right;
font-size:30px;
height:21px;
margin-right:3px;
overflow:hidden;
white-space:nowrap;
width:21px
}

.rate:not(:checked) > label:before {
content:"";
display:block;
height:21px;
width:21px
}

.rate.small {
padding:0;
transform:scale(0.7)
}

.breacrumb-cover {
background-color:#FFF;
padding:20px 0
}

.breadcrumbs li {
color:#101828;
display:inline-block;
font-size:16px;
padding-left:13px;
position:relative
}

.breadcrumbs li::before {
color:#101828;
content:"/";
left:1px;
position:absolute;
top:1px
}

.breadcrumbs li a {
color:#101828;
font-size:16px;
text-decoration:none
}

.breadcrumbs li:first-child {
padding-left:0
}

.text-center .nav {
justify-content:center
}

.display-block,.cb-container input:checked ~ .checkmark:after {
display:block
}

.btn.btn-default:hover,.btn.btn-green-900:hover {
background-color:#101828;
background-image:url(../imgs/template/icons/arrow-small-right-w.svg);
background-repeat:no-repeat;
color:#fff!important;
transform:translateY(-2px);
transition:.2s
}

.btn.btn-white,input:checked + .slider {
background-color:#fff
}

.btn.btn-bd-green-hover:hover,.btn.active {
background-color:#fff!important;
border:2px solid #3F57A7;
color:#3F57A7!important
}

.btn.btn-link:hover,.grid-category-2 .category-info-bottom span {
color:#667085
}

.btn.btn-pink:hover,.block-bill-2 .slider {
background-color:#101828
}

.btn.btn-newsletter ::-moz-placeholder,.btn.btn-newsletter ::placeholder {
color:#98A2B3;
font-size:16px;
line-height:28px
}

.btn.btn-cart:hover,.btn.btn-explorer:hover,.block-pricing-2 .box-pricing-item.active .tag-round,.block-pricing-2 .box-pricing-item:hover .tag-round,.block-pricing-3 .block-price-item.active .tag-round,.block-pricing-3 .block-price-item:hover .tag-round {
background-color:#83C5BE
}

input:-moz-placeholder,textarea:-moz-placeholder,input::-webkit-input-placeholder,textarea::-webkit-input-placeholder,input::-moz-placeholder,textarea::-moz-placeholder,input:-ms-input-placeholder,textarea:-ms-input-placeholder,.box-swiper .swiper-button-next.swiper-button-disabled,.box-swiper .swiper-button-prev.swiper-button-disabled {
opacity:1
}

.form-control:focus,textarea.form-control:focus,.form-group input:focus,.input-round:focus {
background:#fff;
border-color:#1D2939
}

.form-control.form-icons,textarea.form-control.form-icons,.form-group input.form-icons {
padding-left:42px
}

.form-control::-moz-placeholder,.form-control::placeholder {
color:#98A2B3
}

.form-inline,.box-newsletter .box-form-newsletter form {
align-items:center;
display:flex
}

.box-signup .form-field,.box-swiper,.box-swiper .swiper-container,.card-grid-1.bg-business,.card-grid-1.bg-local,.card-grid-1.bg-social {
position:relative
}

.ui-slider-handle,.block-pricing .box-pricing-item .for-year,.block-pricing .box-pricing-item .for-month,.block-pricing-3 .block-pricing-left .box-switch input,.block-pricing-3 .block-price-item .box-info-price .for-year,.block-pricing-3 .block-price-item .box-info-price .for-month,.breadcrumbs li:first-child::before {
display:none
}

.card-grid-1.bg-business::before,.card-grid-1.bg-local::before,.card-grid-1.bg-social::before {
bottom:0;
content:"";
height:90px;
position:absolute;
right:0;
width:90px
}

.card-grid-style-2 .text-comment,.card-grid-style-6 .text-comment {
height:84px;
overflow:hidden;
width:100%
}

.card-grid-style-2.card-square,.card-grid-style-6.card-square {
border-radius:0;
box-shadow:0 45px 80px #0000000a;
padding:30px
}

.card-grid-style-2.card-square .text-comment,.card-grid-style-6.card-square .text-comment {
height:auto
}

.card-grid-style-2.card-square .img-user-round img,.card-grid-style-6.card-square .img-user-round img {
border-radius:50%;
height:80px;
width:80px
}

.card-grid-style-3:hover,.product-item-1:hover,.product-item-2:hover {
box-shadow:0 45px 80px #0000000a
}

.card-grid-style-3.bd-bg-6,.product-item-3:hover {
border-color:#BEE1E6
}

.card-grid-style-4 a.text-heading-4:hover,.card-list-style-1 a:hover {
color:#3F57A7
}

.card-grid-style-4 .grid-4-img.color-bg-4::before,.card-grid-style-4 .grid-4-img.color-bg-5::before,.card-list-style-1 .style-1-img.color-bg-4::before,.card-list-style-1 .style-1-img.color-bg-5::before {
background-color:#FFF
}

.card-grid-style-4 .grid-4-img.color-bg-3::before,.card-list-style-1 .style-1-img.color-bg-3::before {
background-color:#FDE2E4
}

.card-grid-style-4 .grid-4-img.color-bg-2::before,.card-list-style-1 .style-1-img.color-bg-2::before {
background-color:#FFF3EA
}

.card-grid-style-4 .grid-4-img.color-bg-1::before,.card-list-style-1 .style-1-img.color-bg-1::before {
background-color:#EAE4E9
}

.card-grid-style-4 .grid-4-img.color-bg-6::before,.card-list-style-1 .style-1-img.color-bg-6::before {
background-color:#BEE1E6
}

.card-grid-style-4 .grid-4-img.color-bg-7::before,.card-list-style-1 .style-1-img.color-bg-7::before {
background-color:#F0EFEB
}

.card-grid-style-4 .grid-4-img.color-bg-8::before,.card-list-style-1 .style-1-img.color-bg-8::before {
background-color:#DFE7FD
}

.card-grid-style-4 .grid-4-img.color-bg-9::before,.card-list-style-1 .style-1-img.color-bg-9::before {
background-color:#D1ECFD
}

.card-grid-style-4 .grid-4-img.color-bg-10::before,.card-list-style-1 .style-1-img.color-bg-10::before {
background-color:#DDD3FA
}

.block-pricing .box-pricing-item .display-month,.block-pricing .box-pricing-item .display-year,.block-pricing-3 .block-price-item .box-info-price .display-month,.block-pricing-3 .block-price-item .box-info-price .display-year {
display:inline-block
}

.block-bill-2 .slider::before,.product-item-2 .box-quick-view .quick-view a:hover {
background-color:#F2F4F7
}

.block-bill-2 .text-billed,.card-list-style-1 a,.grid-category-2 .category-info-bottom a:hover,.breadcrumbs li a:hover,.breadcrumbs li:last-child::before {
color:#101828
}

.block-bill-2 input:checked + .slider,.block-pricing-3 .block-price-item.active,.block-pricing-3 .block-price-item:hover {
background-color:#3F57A7
}

.block-pricing-2 .box-pricing-item.active .checkbox,.block-pricing-2 .box-pricing-item:hover .checkbox,.block-pricing-3 .block-price-item.active .checkbox,.block-pricing-3 .block-price-item:hover .checkbox {
background:url(../imgs/template/icons/icon-selected.svg) no-repeat 0 0
}

.block-pricing-2 .box-pricing-item.active .text-heading-5,.block-pricing-2 .box-pricing-item.active .text-heading-3,.block-pricing-2 .box-pricing-item.active li,.block-pricing-2 .box-pricing-item:hover .text-heading-5,.block-pricing-2 .box-pricing-item:hover .text-heading-3,.block-pricing-2 .box-pricing-item:hover li,.block-pricing-3 .block-price-item.active .text-heading-5,.block-pricing-3 .block-price-item.active .text-heading-3,.block-pricing-3 .block-price-item:hover .text-heading-5,.block-pricing-3 .block-price-item:hover .text-heading-3 {
color:#fff!important
}

.block-pricing-2 .box-pricing-item.active .text-month,.block-pricing-2 .box-pricing-item:hover .text-month,.block-pricing-3 .block-price-item.active .text-month,.block-pricing-3 .block-price-item:hover .text-month {
color:#BFD1FF!important
}

.product-item-2 .product-info .box-prices,.product-item-2 .product-info .button-add {
width:50%
}

.box-newsletter .box-form-newsletter form .input-newsletter::-moz-placeholder,.box-newsletter .box-form-newsletter form .input-newsletter::placeholder {
color:#98A2B3;
font:400 16px/28px "Noto Sans"
}

@keyframes load7 {
0%,80%,100% {
box-shadow:0 2.5em 0 -1.3em
}

40% {
box-shadow:0 2.5em 0 0
}
}

.loader {
animation:load7 1.8s infinite ease-in-out;
animation-delay:-.16s;
animation-fill-mode:both;
border-radius:50%;
color:#101828;
font-size:10px;
height:2em;
margin:80px auto;
position:relative;
text-indent:-9999em;
transform:translateZ(0);
width:2em
}

.loader:before {
animation:load7 1.8s infinite ease-in-out;
animation-delay:-.32s;
animation-fill-mode:both;
border-radius:50%;
content:"";
height:2em;
left:-3.5em;
position:absolute;
top:0;
width:2em
}

.loader:after {
animation:load7 1.8s infinite ease-in-out;
animation-fill-mode:both;
border-radius:50%;
content:"";
height:2em;
left:3.5em;
position:absolute;
top:0;
width:2em
}

.banner-hero {
padding:60px 0 20px;
position:relative
}

.banner-hero.banner-1 {
background-color:#FAEDE3
}

.banner-hero.banner-1::before {
background:url(../imgs/template/pattern-1.svg) no-repeat;
background-size:cover;
content:"";
height:200px;
position:absolute;
right:0;
top:0;
width:200px;
z-index:1
}

.banner-hero.banner-1::after {
background:url(../imgs/template/pattern-2.svg) no-repeat;
background-size:cover;
bottom:0;
content:"";
height:200px;
left:0;
position:absolute;
width:200px
}

.banner-hero.banner-1 .banner-imgs {
float:right;
height:560px;
padding:20px 0 0;
position:relative;
width:100%
}

.banner-hero.banner-1 .banner-imgs img {
bottom:-40px;
left:0;
max-width:100%;
position:absolute;
right:0;
z-index:1
}

.banner-hero.banner-1 .banner-imgs .video-block span {
color:#fff;
display:inline-block;
list-style:none;
vertical-align:middle
}

.banner-hero.banner-2 {
background-color:#BEE1E6
}

.banner-hero.banner-2 .container {
position:relative;
z-index:4
}

.banner-hero.banner-2::after {
background:url(../imgs/template/pattern-6.svg) no-repeat;
background-size:cover;
bottom:0;
content:"";
height:200px;
left:0;
position:absolute;
width:200px
}

.banner-hero.banner-2 .banner-imgs {
float:right;
padding:20px 0 0;
position:relative;
width:100%
}

.banner-hero.banner-2 .banner-imgs .block-1 {
bottom:20px;
height:260px;
left:-70px;
position:absolute;
width:220px;
z-index:3
}

.banner-hero.banner-2 .banner-imgs .block-2 {
height:169px;
position:absolute;
right:-80px;
top:15%;
width:155px;
z-index:3
}

.banner-hero.banner-4 {
background-color:#3F57A7;
padding:120px 0 80px
}

.banner-hero.banner-4 .banner-imgs .btn-play-video-2 {
background:url(../imgs/template/icons/icon-play-2.svg) no-repeat;
height:109px;
left:-44px;
margin-top:-54px;
position:absolute;
top:50%;
width:116px;
z-index:5
}

.banner-hero.bg-about-1::after {
display:none
}

.banner-hero.bg-about-1 .banner-imgs {
max-width:510px
}

.banner-hero.bg-about-1 .banner-imgs .block-1 {
bottom:0;
left:-220px
}

.banner-hero.bg-about-2 {
background-color:#FFF3EA
}

.banner-hero.bg-about-2 .box-banner-left {
padding-bottom:100px
}

.banner-hero.bg-about-2 .banner-imgs {
height:100%;
position:relative;
width:100%
}

.banner-hero.bg-about-2 .block-1 {
bottom:-47px;
left:-150px;
position:absolute;
width:350px;
z-index:3
}

.banner-hero.bg-service-1 {
background-color:#BEE1E6;
padding-bottom:60px;
padding-top:20px;
position:relative
}

.banner-hero.bg-service-1 * {
z-index:2
}

.banner-hero.bg-service-1 .banner-imgs .block-1 {
left:-90px;
max-width:310px;
position:absolute;
top:-10px;
z-index:2
}

.banner-hero.bg-service-2 {
padding-bottom:60px;
padding-top:50px;
position:relative
}

.banner-hero.bg-service-2 .banner-imgs .block-1 {
left:-110px;
max-width:212px;
position:absolute;
top:-20px;
z-index:2
}

.banner-hero.bg-service-2 .banner-imgs .block-2 {
bottom:110px;
left:-150px;
max-width:310px;
position:absolute;
z-index:2
}

.banner-hero.bg-service-2 .banner-imgs .block-3 {
bottom:50px;
max-width:400px;
position:absolute;
right:-160px;
z-index:2
}

.banner-hero.banner-breadcrums {
background-color:#FFF3EA;
padding:48px 0
}

.banner-hero.banner-head-image {
background-repeat:top center!important;
background-size:cover!important;
padding-bottom:130px;
padding-top:130px;
position:relative
}

.banner-hero.banner-head-image::before {
background-color:#10182880;
content:"";
height:100%;
left:0;
position:absolute;
top:0;
width:100%;
z-index:1
}

.banner-hero.banner-homepage5 .banner-imgs .block-1 {
left:-30px;
position:absolute;
top:-30px;
z-index:12
}

.banner-hero.banner-homepage5 .banner-imgs .block-2 {
bottom:0;
left:0;
position:absolute;
z-index:12
}

.banner-hero.banner-homepage5 .banner-imgs .block-3 {
bottom:-50px;
max-width:230px;
position:absolute;
right:-105px;
z-index:12
}

.banner-hero.banner-homepage6 {
padding-bottom:0;
padding-top:0
}

.banner-hero.banner-homepage6 .banner-imgs {
align-items:flex-end;
bottom:-20px;
display:flex;
height:100%;
position:relative
}

.banner-hero.banner-homepage6 .banner-imgs .img-banner {
position:relative;
z-index:3
}

.banner-hero.banner-homepage6 .banner-imgs .block-1 {
left:-10px;
position:absolute;
top:290px;
z-index:12
}

.banner-hero.banner-homepage6 .banner-imgs .block-2 {
max-width:180px;
position:absolute;
right:-105px;
top:130px;
z-index:1
}

.banner-hero.banner-homepage8 .rounded-img {
border-radius:157px;
box-shadow:0 47px 65px #151c261a;
margin-right:17px;
overflow:hidden;
width:190px
}

.banner-hero.banner-homepage8 .rounded-img img {
height:100%
}

.banner-hero.banner-1 .container,.banner-hero.banner-head-image .container {
position:relative;
z-index:2
}

.banner-hero.banner-2 .banner-imgs .block-1 img,.banner-hero.banner-2 .banner-imgs .block-2 img,.banner-hero.bg-about-2 .block-1 img {
border-radius:10px
}

.banner-hero.banner-4 .banner-imgs,.banner-hero.banner-homepage5 .banner-imgs {
position:relative
}

.banner-hero.bg-service-1 .banner-imgs,.banner-hero.bg-service-2 .banner-imgs {
margin-top:140px;
position:relative
}

.banner-hero.bg-service-1 .banner-imgs img,.banner-hero.bg-service-2 .banner-imgs img,.banner-hero.banner-homepage5 .banner-imgs .block-3 img,.banner-hero.banner-homepage6 .banner-imgs .block-2 img {
border-radius:16px;
box-shadow:0 47px 65px #151c261a
}

.banner-hero.bg-service-1 .banner-imgs .block-1 img,.banner-hero.bg-service-2 .banner-imgs .block-1 img,.banner-hero.bg-service-2 .banner-imgs .block-2 img,.banner-hero.bg-service-2 .banner-imgs .block-3 img {
border-radius:8px;
box-shadow:0 47px 65px #151c261a
}

@keyframes hero-thumb-animation {
0% {
transform:translateY(-20px)
}

100% {
transform:translateY(0px)
}
}

@keyframes hero-thumb-sm-animation {
0% {
transform:translateY(-20px) translateX(50px)
}

100% {
transform:translateY(-20px) translateX(0px)
}
}

@keyframes hero-thumb-sm-2-animation {
0% {
transform:translateY(-50px)
}

100% {
transform:translateY(0px)
}
}

.shape-1 {
animation:hero-thumb-animation 2s linear infinite alternate
}

.shape-2 {
animation:hero-thumb-sm-animation 4s linear infinite alternate
}

.shape-3 {
animation:hero-thumb-sm-2-animation 4s linear infinite alternate
}

.header {
float:left;
padding:10px 0;
position:relative;
width:100%
}

.header .main-header {
align-items:center;
display:flex;
justify-content:space-between
}

.header .main-header .header-left {
align-items:center;
display:flex;
width:100%
}

.header .main-header .header-left .header-logo {
margin-right:50px
}

.header .main-header .header-left .header-nav {
display:inline-block;
text-align:center;
width:100%
}

.header .main-header .header-right {
max-width:225px;
width:100%
}

.header .main-header .header-right .block-signin {
text-align:right
}

.header .nav-main-menu {
float:left;
padding:0;
width:100%
}

.header .main-menu {
display:inline-block;
padding-top:5px
}

.header .main-menu li {
float:left;
position:relative;
text-align:left
}

.header .main-menu li.has-children > a::after {
content:"\f151";
font:400 14px/1 uicons-regular-rounded!important;
margin-left:4px;
opacity:1;
text-transform:none
}

.header .main-menu li.hr {
padding:0 22px
}

.header .main-menu li a {
color:#101828;
display:block;
font:400 18px "Chivo",sans-serif;
padding:30px 22px;
position:relative;
text-decoration:none
}

.header .main-menu li a:hover i {
opacity:1;
transition:.2s
}

.header .main-menu li a i {
font-size:12px;
margin-right:8px;
opacity:.38;
transition:.2s
}

.header .main-menu li ul {
background-color:#fff;
border-radius:4px;
box-shadow:4px 4px 40px #1018281a;
left:0;
min-width:180px;
opacity:0;
padding:8px 0;
position:absolute;
top:100%;
transition-duration:.2s;
visibility:hidden;
z-index:999
}

.header .main-menu li ul li a {
color:#101828;
font-size:14px;
padding:10px 22px;
position:relative;
transition:.2s
}

.header .main-menu li ul li a:hover {
padding-left:25px;
transition:.2s
}

.header .main-menu li ul li a.closer {
padding:8px 22px
}

.header .main-menu li ul li ul {
left:100%;
top:0
}

.header .main-menu li ul.two-col {
columns:2;
min-width:350px
}

.header .text-link-bd-btom {
color:#3F57A7;
display:inline-block;
font:500 16px/150% "Noto Sans",sans-serif;
padding-left:13px;
position:relative
}

.header .text-link-bd-btom::before {
animation:shadow-pulse 1s infinite;
background:#3F57A7;
border-radius:50%;
content:"";
height:6px;
left:0;
margin-top:-3px;
position:absolute;
top:50%;
width:6px
}

.header .block-signin .btn-shadow {
box-shadow:0 47px 65px #151c261a
}

.header.header-2 {
background-color:#FFF
}

.sticky-bar.stick {
animation:700ms ease-in-out 0s normal none 1 running fadeInDown;
background:#fff;
border-bottom:1px solid #F2F4F7;
box-shadow:0 8px 20px 0 #0000000d;
left:0;
position:fixed;
top:0;
transition:all .3s ease 0s;
width:100%;
z-index:999
}

.sticky-bar.stick.sticky-white-bg {
background-color:#fff
}

.sticky-bar.stick.sticky-blue-bg {
background-color:#3286e0
}

.sticky-bar.stick .burger-icon {
top:45px
}

.user-account {
align-items:center;
display:flex
}

.user-account img {
border-radius:50%;
margin-right:10px;
max-width:50px
}

.user-account .user-name {
font-size:14px;
font-weight:600;
margin-bottom:0
}

.perfect-scroll {
height:100vh;
position:relative;
width:300px
}

.body-overlay-1 {
background:transparent;
cursor:crosshair;
height:100%;
left:0;
opacity:0;
position:fixed;
top:0;
visibility:hidden;
width:100%;
z-index:997
}

.header-top {
background-color:#101828;
color:#fff;
padding:10px 0
}

.header-top span {
font:700 16px/24px "Noto Sans",sans-serif
}

.header-top a {
color:#E29578
}

.header-top .line-right {
display:inline-block;
padding-right:35px;
position:relative
}

.header-top .line-right::before {
border-right:1px dashed #E29578;
content:"";
height:18px;
position:absolute;
right:15px;
top:5px;
width:1px
}

.header-top.header-top-green a {
color:#AFEAE4
}

.header-top.header-top-green .line-right::before {
border-right:1px dashed #AFEAE4
}

.header-style-2 .btn-default {
background-color:#3F57A7;
background-image:url(../imgs/template/icons/arrow-small-right-w.svg);
background-position:center right 25px;
background-repeat:no-repeat;
border:1px solid #fff;
color:#fff;
padding-right:56px
}

.header-style-5 {
z-index:2
}

.header-style-5 .btn-default {
background-color:transparent;
background-image:url(../imgs/template/icons/arrow-small-right-w.svg);
background-position:center right 25px;
background-repeat:no-repeat;
border:1px solid #fff;
color:#fff;
padding-right:56px
}

.mobile-header-wrapper-style {
background-color:#fff;
bottom:0;
box-shadow:0 0 15px 0 #00000017;
min-height:100vh;
opacity:0;
position:fixed;
right:0;
top:0;
transition:all .25s cubic-bezier(0.645,0.045,0.355,1);
visibility:hidden;
width:380px;
z-index:1000
}

.mobile-header-wrapper-style.sidebar-visible {
opacity:1;
transform:translate(0,0);
visibility:visible
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner {
height:100%;
overflow-x:hidden;
overflow-y:auto;
padding:0 0 30px
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top {
align-items:center;
align-self:center;
background-color:#fff;
border-bottom:thin solid #F2F4F7;
display:flex;
flex-wrap:wrap;
justify-content:space-between;
padding:34px 30px 30px
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top .mobile-header-logo a {
display:block
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top .mobile-header-logo a img {
width:100px
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area {
padding:30px
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li {
display:block;
padding:13px 0;
position:relative
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li:last-child {
border-bottom:none
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.has-children .menu-expand {
color:#101828;
cursor:pointer;
display:block;
font-size:12px;
height:30px;
line-height:38px;
position:absolute;
right:0;
text-align:center;
top:5px;
width:30px;
z-index:9
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.has-children .menu-expand i {
font-size:18px;
font-weight:300;
opacity:.5
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.has-children.active > .menu-expand {
background:#fff3
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.has-children.active > .menu-expand i::before {
content:"\f113"
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li a {
color:#101828;
display:inline-block;
font:500 16px/1 "Chivo",sans-serif;
position:relative;
text-transform:capitalize;
transition-duration:.2s
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li a i {
margin-right:5px
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul {
padding:10px 0 0 10px
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li {
border-bottom:none;
padding:10px 0
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li.has-children .menu-expand {
top:0
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li:hover a {
padding-left:13px;
transition-duration:.2s
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li a {
color:#101828;
display:block;
font-size:14px;
font-weight:500;
padding-left:10px;
position:relative;
transition-duration:.2s
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li ul {
margin-top:0
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li.hr {
padding:0 0 0 10px
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li:hover > a {
color:#3F57A7;
padding-left:3px;
transition-duration:.2s
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .categories-dropdown-wrap ul li a {
padding:5px 15px
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap {
border:1px solid #101828;
border-radius:5px;
margin:17px 0 30px;
padding:20px
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info {
margin-bottom:13px;
position:relative
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info:last-child {
margin-bottom:0
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info a {
color:#3F57A7;
display:block;
font-size:14px;
font-weight:500
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info a i {
color:#101828;
font-size:14px;
margin-right:8px
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown {
background-color:transparent;
box-shadow:none;
display:none;
margin-top:5px;
padding:10px 0 0;
width:100%;
z-index:11
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li {
padding-bottom:10px
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li a {
color:#ffffffb3;
font-size:16px;
font-weight:400
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-border {
border-bottom:1px solid #ffffff26
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .site-copyright {
color:#101828;
font-size:13px
}

.burger-icon {
cursor:pointer;
height:20px;
position:absolute;
right:27px;
top:45px;
transition:all .25s cubic-bezier(0.645,0.045,0.355,1);
width:24px;
z-index:1002
}

.burger-icon > span {
display:block;
height:2px;
left:0;
position:absolute;
width:100%
}

.burger-icon > span.burger-icon-top {
top:2px
}

.burger-icon > span.burger-icon-bottom {
bottom:2px
}

.burger-icon > span.burger-icon-mid {
top:9px
}

.burger-icon.burger-close {
position:fixed
}

.burger-icon.burger-close > span.burger-icon-top {
display:none;
opacity:0
}

.burger-icon.burger-close > span.burger-icon-mid {
top:8px;
transform:rotate(45deg)
}

.burger-icon.burger-close > span.burger-icon-bottom {
bottom:10px;
transform:rotate(-45deg)
}

.burger-icon > span::before,.burger-icon > span::after {
background-color:#333;
content:"";
height:100%;
left:0;
position:absolute;
top:0;
width:100%
}

.burger-icon.burger-icon-white > span::before,.burger-icon.burger-icon-white > span::after {
background-color:#667085
}

.mobile-search form {
background-color:#EAE4E9;
border-radius:8px;
height:44px;
padding:0 15px 0 44px;
position:relative
}

.mobile-search form input {
background-color:transparent;
border:0;
padding:12px 10px;
width:100%
}

.mobile-search form i {
color:#1D2939;
font-size:18px;
left:18px;
position:absolute;
top:14px
}

.mobile-social-icon h6 {
font-size:20px;
font-weight:600;
margin-bottom:30px
}

.mobile-social-icon a {
align-content:center;
background:#efeaff;
border-radius:30px;
display:inline-flex;
font-size:14px;
height:30px;
justify-content:center;
line-height:1;
margin-right:5px;
text-align:center;
transition-duration:.5s;
width:30px
}

.mobile-social-icon a img {
max-width:18px
}

.mobile-social-icon a img:hover {
opacity:.8
}

.mobile-social-icon a:hover {
margin-top:-2px;
transform:translateY(-2px);
transition-duration:.5s
}

.mobile-social-icon a:last-child {
margin-right:0
}

.mobile-account {
border-bottom:thin solid #F2F4F7;
border-top:thin solid #F2F4F7;
margin:20px 0 25px;
padding:30px 0 20px
}

.mobile-account h6 {
font-size:18px;
font-weight:600
}

.mobile-account ul li {
padding:13px 0
}

.mobile-account ul li a {
color:#101828;
display:inline-block;
font-size:15px;
font-weight:500;
line-height:1;
position:relative;
text-transform:capitalize;
transition-duration:.2s
}

.mobile-account ul li:hover a {
color:#3F57A7;
padding-left:3px;
transition-duration:.2s
}

.sidebar-title {
border-bottom:thin solid #101828;
font-weight:600;
margin-bottom:30px;
padding-bottom:10px
}

.search-form form input {
border:1px solid #ececec;
border-radius:10px;
box-shadow:none;
font-size:16px;
height:64px;
padding-left:20px;
width:100%
}

.search-form form button {
background-color:transparent;
border:none;
color:#242424;
font-size:20px;
height:100%;
padding:0 24px;
position:absolute;
right:0;
top:50%;
transform:translateY(-50%)
}

.widget-categories {
background-color:#FFF3EA!important
}

.widget-categories li {
padding:8px 0
}

.widget-categories li + li {
border-top:1px solid #101828
}

.widget-categories li a {
color:#3F57A7;
font-size:16px
}

.widget-categories li span.count {
background-color:#DDD3FA;
border-radius:3px;
color:#3F57A7;
display:block;
min-width:26px;
padding:3px 5px;
text-align:center
}

.post-list-small-item {
transition-duration:.2s
}

.post-list-small-item:hover {
transform:translateY(-3px);
transition-duration:.2s
}

.post-list-small-item figure img {
border-radius:5px;
max-width:85px
}

.post-list-small-item .post-meta {
font-size:13px
}

.post-list-small-item .post-meta img {
border-radius:50%;
margin-right:5px;
max-width:30px
}

.post-list-small-item h5 {
font-size:14px;
font-weight:600;
line-height:1.2;
margin-bottom:10px
}

.post-list-small-item:not(:last-child) {
margin-bottom:15px
}

.footer .width-20 {
width:21%
}

.footer .width-16 {
width:16%
}

.footer .footer-top {
border-bottom:1px solid #E4E7EC;
display:inline-block;
margin-bottom:40px;
padding:0 0 40px;
width:100%
}

.footer .menu-footer {
display:inline-block
}

.footer .menu-footer li {
list-style:none;
margin-bottom:8px
}

.footer .menu-footer li a {
color:#667085;
font:16px/28px "Noto Sans",sans-serif;
text-decoration:none;
transition-duration:.2s
}

.footer .menu-footer li a:hover {
color:#3F57A7;
padding-left:3px;
transition-duration:.2s
}

.footer .footer-bottom {
border-top:1px solid #E4E7EC;
color:#667085;
font-size:14px;
padding:30px 0
}

.footer .footer-bottom a {
text-decoration:none
}

.footer .footer-bottom .icon-socials {
display:inline-block;
height:32px;
margin-left:10px;
transition-duration:.2s;
width:32px
}

.footer .footer-bottom .icon-socials.icon-facebook {
background:url(../imgs/template/icons/facebook.svg) no-repeat 0 0;
transition-duration:.2s
}

.footer .footer-bottom .icon-socials.icon-twitter {
background:url(../imgs/template/icons/twitter.svg) no-repeat 0 0;
transition-duration:.2s
}

.footer .footer-bottom .icon-socials.icon-instagram {
background:url(../imgs/template/icons/instagram.svg) no-repeat 0 0;
transition-duration:.2s
}

.footer .footer-bottom .icon-socials.icon-linkedin {
background:url(../imgs/template/icons/linkedin.svg) no-repeat 0 0;
transition-duration:.2s
}

#scrollUp {
background-color:#fff;
border:0;
border-radius:30px;
bottom:30px;
color:#fff;
height:42px;
overflow:hidden;
right:30px;
text-align:center;
transition-duration:.2s;
width:42px;
z-index:999!important
}

#scrollUp i {
color:#fff;
display:block;
font-size:20px;
line-height:46px!important
}

.section-padding {
padding:25px 0
}

.ptb-0 {
padding:0
}

.ptb-20 {
padding:20px 0
}

.ptb-35 {
padding:35px 0
}

.ptb-32 {
padding:32px 0
}

.ptb-40 {
padding:40px 0
}

.ptb-70 {
padding:70px 0
}

.ptb-80 {
padding:80px 0
}

.ptb-90 {
padding:90px 0
}

.ptb-100 {
padding:100px 0
}

.ptb-110 {
padding:110px 0
}

.ptb-120 {
padding:120px 0
}

.ptb-130 {
padding:130px 0
}

.ptb-140 {
padding:140px 0
}

.ptb-150 {
padding:150px 0
}

.ptb-160 {
padding:160px 0
}

.ptb-170 {
padding:170px 0
}

.ptb-177 {
padding:177px 0
}

.ptb-180 {
padding:180px 0
}

.ptb-190 {
padding:190px 0
}

.ptb-200 {
padding:200px 0
}

.ptb-210 {
padding:210px 0
}

.ptb-220 {
padding:220px 0
}

.ptb-290 {
padding:290px 0
}

.ptb-310 {
padding:310px 0
}

.p-10 {
padding:10px!important
}

.p-15 {
padding:15px!important
}

.p-20 {
padding:20px!important
}

.p-25 {
padding:25px!important
}

.p-30 {
padding:30px!important
}

.p-40 {
padding:40px!important
}

.p-65 {
padding:65px!important
}

.pt-5 {
padding-top:5px!important
}

.pt-10 {
padding-top:10px!important
}

.pt-15 {
padding-top:15px!important
}

.pt-20 {
padding-top:20px!important
}

.pt-25 {
padding-top:25px!important
}

.pt-30 {
padding-top:30px!important
}

.pt-35 {
padding-top:35px!important
}

.pt-40 {
padding-top:40px!important
}

.pt-45 {
padding-top:45px!important
}

.pt-50 {
padding-top:50px!important
}

.pt-55 {
padding-top:55px!important
}

.pt-60 {
padding-top:60px!important
}

.pt-65 {
padding-top:65px!important
}

.pt-70 {
padding-top:70px!important
}

.pt-75 {
padding-top:75px!important
}

.pt-80 {
padding-top:80px!important
}

.pt-85 {
padding-top:85px!important
}

.pt-90 {
padding-top:90px!important
}

.pt-95 {
padding-top:95px!important
}

.pt-100 {
padding-top:100px!important
}

.pt-105 {
padding-top:105px!important
}

.pt-110 {
padding-top:110px!important
}

.pt-115 {
padding-top:115px!important
}

.pt-120 {
padding-top:120px!important
}

.pt-125 {
padding-top:125px!important
}

.pt-130 {
padding-top:130px!important
}

.pt-135 {
padding-top:135px!important
}

.pt-140 {
padding-top:140px!important
}

.pt-145 {
padding-top:145px!important
}

.pt-150 {
padding-top:150px!important
}

.pt-155 {
padding-top:155px!important
}

.pt-160 {
padding-top:160px!important
}

.pt-165 {
padding-top:165px!important
}

.pt-170 {
padding-top:170px!important
}

.pt-175 {
padding-top:175px!important
}

.pt-180 {
padding-top:180px!important
}

.pt-185 {
padding-top:185px!important
}

.pt-190 {
padding-top:190px!important
}

.pt-195 {
padding-top:195px!important
}

.pt-200 {
padding-top:200px!important
}

.pt-260 {
padding-top:260px!important
}

.pb-5 {
padding-bottom:5px!important
}

.pb-10 {
padding-bottom:10px!important
}

.pb-15 {
padding-bottom:15px!important
}

.pb-20 {
padding-bottom:20px!important
}

.pb-25 {
padding-bottom:25px!important
}

.pb-30 {
padding-bottom:30px!important
}

.pb-35 {
padding-bottom:35px!important
}

.pb-40 {
padding-bottom:40px!important
}

.pb-45 {
padding-bottom:45px!important
}

.pb-50 {
padding-bottom:50px!important
}

.pb-55 {
padding-bottom:55px!important
}

.pb-60 {
padding-bottom:60px!important
}

.pb-65 {
padding-bottom:65px!important
}

.pb-70 {
padding-bottom:70px!important
}

.pb-75 {
padding-bottom:75px!important
}

.pb-80 {
padding-bottom:80px!important
}

.pb-85 {
padding-bottom:85px!important
}

.pb-90 {
padding-bottom:90px!important
}

.pb-95 {
padding-bottom:95px!important
}

.pb-100 {
padding-bottom:100px!important
}

.pb-105 {
padding-bottom:105px!important
}

.pb-110 {
padding-bottom:110px!important
}

.pb-115 {
padding-bottom:115px!important
}

.pb-120 {
padding-bottom:120px!important
}

.pb-125 {
padding-bottom:125px!important
}

.pb-130 {
padding-bottom:130px!important
}

.pb-135 {
padding-bottom:135px!important
}

.pb-140 {
padding-bottom:140px!important
}

.pb-145 {
padding-bottom:145px!important
}

.pb-150 {
padding-bottom:150px!important
}

.pb-155 {
padding-bottom:155px!important
}

.pb-160 {
padding-bottom:160px!important
}

.pb-165 {
padding-bottom:165px!important
}

.pb-170 {
padding-bottom:170px!important
}

.pb-175 {
padding-bottom:175px!important
}

.pb-180 {
padding-bottom:180px!important
}

.pb-185 {
padding-bottom:185px!important
}

.pb-190 {
padding-bottom:190px!important
}

.pb-195 {
padding-bottom:195px!important
}

.pb-200 {
padding-bottom:200px!important
}

.pl-0 {
padding-left:0!important
}

.pl-5 {
padding-left:5px!important
}

.pl-10 {
padding-left:10px!important
}

.pl-15 {
padding-left:15px!important
}

.pl-20 {
padding-left:20px!important
}

.pl-25 {
padding-left:25px!important
}

.pl-30 {
padding-left:30px!important
}

.pl-35 {
padding-left:35px!important
}

.pl-40 {
padding-left:40px!important
}

.pl-45 {
padding-left:45px!important
}

.pl-50 {
padding-left:50px!important
}

.pl-55 {
padding-left:55px!important
}

.pl-60 {
padding-left:60px!important
}

.pl-65 {
padding-left:65px!important
}

.pl-70 {
padding-left:70px!important
}

.pl-75 {
padding-left:75px!important
}

.pl-80 {
padding-left:80px!important
}

.pl-85 {
padding-left:85px!important
}

.pl-90 {
padding-left:90px!important
}

.pl-95 {
padding-left:95px!important
}

.pl-100 {
padding-left:100px!important
}

.pl-105 {
padding-left:105px!important
}

.pl-110 {
padding-left:110px!important
}

.pl-115 {
padding-left:115px!important
}

.pl-120 {
padding-left:120px!important
}

.pl-125 {
padding-left:125px!important
}

.pl-130 {
padding-left:130px!important
}

.pl-135 {
padding-left:135px!important
}

.pl-140 {
padding-left:140px!important
}

.pl-145 {
padding-left:145px!important
}

.pl-150 {
padding-left:150px!important
}

.pl-155 {
padding-left:155px!important
}

.pl-160 {
padding-left:160px!important
}

.pl-165 {
padding-left:165px!important
}

.pl-170 {
padding-left:170px!important
}

.pl-175 {
padding-left:175px!important
}

.pl-180 {
padding-left:180px!important
}

.pl-185 {
padding-left:185px!important
}

.pl-190 {
padding-left:190px!important
}

.pl-195 {
padding-left:195px!important
}

.pl-200 {
padding-left:200px!important
}

.pr-0 {
padding-right:0!important
}

.pr-5 {
padding-right:5px!important
}

.pr-10 {
padding-right:10px!important
}

.pr-15 {
padding-right:15px!important
}

.pr-20 {
padding-right:20px!important
}

.pr-25 {
padding-right:25px!important
}

.pr-30 {
padding-right:30px!important
}

.pr-35 {
padding-right:35px!important
}

.pr-40 {
padding-right:40px!important
}

.pr-45 {
padding-right:45px!important
}

.pr-50 {
padding-right:50px!important
}

.pr-55 {
padding-right:55px!important
}

.pr-60 {
padding-right:60px!important
}

.pr-65 {
padding-right:65px!important
}

.pr-70 {
padding-right:70px!important
}

.pr-75 {
padding-right:75px!important
}

.pr-80 {
padding-right:80px!important
}

.pr-85 {
padding-right:85px!important
}

.pr-90 {
padding-right:90px!important
}

.pr-95 {
padding-right:95px!important
}

.pr-100 {
padding-right:100px!important
}

.pr-105 {
padding-right:105px!important
}

.pr-110 {
padding-right:110px!important
}

.pr-115 {
padding-right:115px!important
}

.pr-120 {
padding-right:120px!important
}

.pr-125 {
padding-right:125px!important
}

.pr-130 {
padding-right:130px!important
}

.pr-135 {
padding-right:135px!important
}

.pr-140 {
padding-right:140px!important
}

.pr-145 {
padding-right:145px!important
}

.pr-150 {
padding-right:150px!important
}

.pr-155 {
padding-right:155px!important
}

.pr-160 {
padding-right:160px!important
}

.pr-165 {
padding-right:165px!important
}

.pr-170 {
padding-right:170px!important
}

.pr-175 {
padding-right:175px!important
}

.pr-180 {
padding-right:180px!important
}

.pr-185 {
padding-right:185px!important
}

.pr-190 {
padding-right:190px!important
}

.pr-195 {
padding-right:195px!important
}

.pr-200 {
padding-right:200px!important
}

.plr-5-percent {
padding:0 5%
}

.mtb-0 {
margin:0
}

.mtb-10 {
margin:10px 0
}

.mtb-15 {
margin:15px 0
}

.mtb-20 {
margin:20px 0
}

.mtb-30 {
margin:30px 0
}

.mtb-40 {
margin:40px 0
}

.mtb-50 {
margin:50px 0
}

.mtb-60 {
margin:60px 0
}

.mtb-70 {
margin:70px 0
}

.mtb-80 {
margin:80px 0
}

.mtb-90 {
margin:90px 0
}

.mtb-100 {
margin:100px 0
}

.mtb-110 {
margin:110px 0
}

.mtb-120 {
margin:120px 0
}

.mtb-130 {
margin:130px 0
}

.mtb-140 {
margin:140px 0
}

.mtb-150 {
margin:150px 0
}

.mtb-290 {
margin:290px 0
}

.mb-24 {
margin-bottom:24px
}

.mt-5 {
margin-top:5px!important
}

.mt-8 {
margin-top:8px!important
}

.mt-10 {
margin-top:10px!important
}

.mt-15 {
margin-top:15px!important
}

.mt-20 {
margin-top:20px!important
}

.mt-25 {
margin-top:25px!important
}

.mt-30 {
margin-top:30px!important
}

.mt-35 {
margin-top:35px!important
}

.mt-40 {
margin-top:40px!important
}

.mt-45 {
margin-top:45px!important
}

.mt-50 {
margin-top:50px!important
}

.mt-55 {
margin-top:55px!important
}

.mt-60 {
margin-top:60px!important
}

.mt-65 {
margin-top:65px!important
}

.mt-70 {
margin-top:70px!important
}

.mt-75 {
margin-top:75px!important
}

.mt-80 {
margin-top:80px!important
}

.mt-85 {
margin-top:85px!important
}

.mt-90 {
margin-top:90px!important
}

.mt-95 {
margin-top:95px!important
}

.mt-100 {
margin-top:100px!important
}

.mt-105 {
margin-top:105px!important
}

.mt-110 {
margin-top:110px!important
}

.mt-115 {
margin-top:115px!important
}

.mt-120 {
margin-top:120px!important
}

.mt-125 {
margin-top:125px!important
}

.mt-130 {
margin-top:130px!important
}

.mt-135 {
margin-top:135px!important
}

.mt-140 {
margin-top:140px!important
}

.mt-145 {
margin-top:145px!important
}

.mt-150 {
margin-top:150px!important
}

.mt-155 {
margin-top:155px!important
}

.mt-160 {
margin-top:160px!important
}

.mt-165 {
margin-top:165px!important
}

.mt-170 {
margin-top:170px!important
}

.mt-175 {
margin-top:175px!important
}

.mt-180 {
margin-top:180px!important
}

.mt-185 {
margin-top:185px!important
}

.mt-190 {
margin-top:190px!important
}

.mt-195 {
margin-top:195px!important
}

.mt-200 {
margin-top:200px!important
}

.mb-5 {
margin-bottom:5px!important
}

.mb-10 {
margin-bottom:10px!important
}

.mb-15 {
margin-bottom:15px!important
}

.mb-20 {
margin-bottom:20px!important
}

.mb-25 {
margin-bottom:25px!important
}

.mb-30 {
margin-bottom:30px!important
}

.mb-35 {
margin-bottom:35px!important
}

.mb-40 {
margin-bottom:40px!important
}

.mb-45 {
margin-bottom:45px!important
}

.mb-50 {
margin-bottom:50px!important
}

.mb-55 {
margin-bottom:55px!important
}

.mb-60 {
margin-bottom:60px!important
}

.mb-65 {
margin-bottom:65px!important
}

.mb-70 {
margin-bottom:70px!important
}

.mb-75 {
margin-bottom:75px!important
}

.mb-80 {
margin-bottom:80px!important
}

.mb-85 {
margin-bottom:85px!important
}

.mb-90 {
margin-bottom:90px!important
}

.mb-95 {
margin-bottom:95px!important
}

.mb-100 {
margin-bottom:100px!important
}

.mb-105 {
margin-bottom:105px!important
}

.mb-110 {
margin-bottom:110px!important
}

.mb-115 {
margin-bottom:115px!important
}

.mb-120 {
margin-bottom:120px!important
}

.mb-125 {
margin-bottom:125px!important
}

.mb-130 {
margin-bottom:130px!important
}

.mb-135 {
margin-bottom:135px!important
}

.mb-140 {
margin-bottom:140px!important
}

.mb-145 {
margin-bottom:145px!important
}

.mb-150 {
margin-bottom:150px!important
}

.mb-155 {
margin-bottom:155px!important
}

.mb-160 {
margin-bottom:160px!important
}

.mb-165 {
margin-bottom:165px!important
}

.mb-170 {
margin-bottom:170px!important
}

.mb-175 {
margin-bottom:175px!important
}

.mb-180 {
margin-bottom:180px!important
}

.mb-185 {
margin-bottom:185px!important
}

.mb-190 {
margin-bottom:190px!important
}

.mb-195 {
margin-bottom:195px!important
}

.mb-200 {
margin-bottom:200px!important
}

.ml-0 {
margin-left:0!important
}

.ml-5 {
margin-left:5px!important
}

.ml-10 {
margin-left:10px!important
}

.ml-15 {
margin-left:15px!important
}

.ml-20 {
margin-left:20px!important
}

.ml-25 {
margin-left:25px!important
}

.ml-30 {
margin-left:30px!important
}

.ml-35 {
margin-left:35px!important
}

.ml-40 {
margin-left:40px!important
}

.ml-45 {
margin-left:45px!important
}

.ml-50 {
margin-left:50px!important
}

.ml-55 {
margin-left:55px!important
}

.ml-60 {
margin-left:60px!important
}

.ml-65 {
margin-left:65px!important
}

.ml-70 {
margin-left:70px!important
}

.ml-75 {
margin-left:75px!important
}

.ml-80 {
margin-left:80px!important
}

.ml-85 {
margin-left:85px!important
}

.ml-90 {
margin-left:90px!important
}

.ml-95 {
margin-left:95px!important
}

.ml-100 {
margin-left:100px!important
}

.ml-105 {
margin-left:105px!important
}

.ml-110 {
margin-left:110px!important
}

.ml-115 {
margin-left:115px!important
}

.ml-120 {
margin-left:120px!important
}

.ml-125 {
margin-left:125px!important
}

.ml-130 {
margin-left:130px!important
}

.ml-135 {
margin-left:135px!important
}

.ml-140 {
margin-left:140px!important
}

.ml-145 {
margin-left:145px!important
}

.ml-150 {
margin-left:150px!important
}

.ml-155 {
margin-left:155px!important
}

.ml-160 {
margin-left:160px!important
}

.ml-165 {
margin-left:165px!important
}

.ml-170 {
margin-left:170px!important
}

.ml-175 {
margin-left:175px!important
}

.ml-180 {
margin-left:180px!important
}

.ml-185 {
margin-left:185px!important
}

.ml-190 {
margin-left:190px!important
}

.ml-195 {
margin-left:195px!important
}

.ml-200 {
margin-left:200px!important
}

.mr-5 {
margin-right:5px!important
}

.mr-10 {
margin-right:10px!important
}

.mr-15 {
margin-right:15px!important
}

.mr-20 {
margin-right:20px!important
}

.mr-25 {
margin-right:25px!important
}

.mr-30 {
margin-right:30px!important
}

.mr-35 {
margin-right:35px!important
}

.mr-40 {
margin-right:40px!important
}

.mr-45 {
margin-right:45px!important
}

.mr-50 {
margin-right:50px!important
}

.mr-55 {
margin-right:55px!important
}

.mr-60 {
margin-right:60px!important
}

.mr-65 {
margin-right:65px!important
}

.mr-70 {
margin-right:70px!important
}

.mr-75 {
margin-right:75px!important
}

.mr-80 {
margin-right:80px!important
}

.mr-85 {
margin-right:85px!important
}

.mr-90 {
margin-right:90px!important
}

.mr-95 {
margin-right:95px!important
}

.mr-100 {
margin-right:100px!important
}

.mr-105 {
margin-right:105px!important
}

.mr-110 {
margin-right:110px!important
}

.mr-115 {
margin-right:115px!important
}

.mr-120 {
margin-right:120px!important
}

.mr-125 {
margin-right:125px!important
}

.mr-130 {
margin-right:130px!important
}

.mr-135 {
margin-right:135px!important
}

.mr-140 {
margin-right:140px!important
}

.mr-145 {
margin-right:145px!important
}

.mr-150 {
margin-right:150px!important
}

.mr-155 {
margin-right:155px!important
}

.mr-160 {
margin-right:160px!important
}

.mr-165 {
margin-right:165px!important
}

.mr-170 {
margin-right:170px!important
}

.mr-175 {
margin-right:175px!important
}

.mr-180 {
margin-right:180px!important
}

.mr-185 {
margin-right:185px!important
}

.mr-190 {
margin-right:190px!important
}

.mr-195 {
margin-right:195px!important
}

.mr-200 {
margin-right:200px!important
}

.item-logo {
display:inline-block;
line-height:40px;
padding:15px;
width:100%
}

.item-logo img {
vertical-align:middle
}

.box-hover-shadow:hover {
box-shadow:14px 14px 36px #99999938
}

.block-video.icon-pattern::before {
background:url(../imgs/template/pattern-3.svg) no-repeat;
content:"";
height:132px;
position:absolute;
right:0;
top:0;
width:146px;
z-index:2
}

.block-video.icon-pattern img {
position:relative;
z-index:1
}

.block-video .btn-play-video {
background-size:135px;
height:135px;
left:-68px;
margin-top:-68px;
width:135px;
z-index:3
}

.line-bd-green {
border:1px dashed #3F57A7
}

.bdrd-16 {
border-radius:16px
}

.bdrd-58 {
border-radius:58px
}

.tag-1 {
background-color:#FFF3EA;
border-radius:50px;
color:#E29578;
display:inline-block;
font:14px/14px "Chivo",sans-serif;
padding:14px 28px
}

.tag-dot {
background:url(../imgs/template/icons/dot.svg) no-repeat left center;
color:#667085;
display:block;
font:12px/16px "Noto Sans",sans-serif;
padding:0 0 0 10px;
text-transform:uppercase
}

.pattern-white {
padding-left:130px;
padding-right:130px;
position:relative
}

.pattern-white * {
position:relative;
z-index:2
}

.pattern-white::before {
background:url(../imgs/template/pattern-white.svg) no-repeat top right;
content:"";
height:162px;
left:0;
position:absolute;
top:0;
width:120px;
z-index:1
}

.section-green {
background-color:#3F57A7;
padding:20px 0 50px;
position:relative
}

.section-green::before {
background:url(../imgs/template/pattern-4.svg) no-repeat left bottom;
content:"";
height:150px;
opacity:.3;
position:absolute;
right:0;
top:0;
width:150px
}

.section-green::after {
background:url(../imgs/template/pattern-5.svg) no-repeat right top;
bottom:0;
content:"";
height:145px;
left:0;
opacity:.3;
position:absolute;
width:108px
}

.box-optimized {
padding:60px
}

.mw-720 {
display:table!important;
margin:auto;
max-width:720px;
table-layout:fixed
}

.banner-home-3 {
padding-bottom:140px!important;
position:relative
}

.banner-home-3 .banner-hero::before {
display:none
}

.banner-home-3 .banner-hero .block-banner {
padding-right:80px
}

.banner-home-3::before {
background-size:contain;
bottom:40px;
content:"";
height:351px;
left:40px;
position:absolute;
width:400px
}

.banner-home-3::after {
background-size:contain;
bottom:40px;
content:"";
height:351px;
position:absolute;
right:20px;
width:400px
}

.list-job-2-col .card-job-description {
font-size:14px;
line-height:22px
}

.findjob-homepage-2::before {
bottom:-55px!important;
right:100px!important
}

.block-job-bg-homepage-2::before {
right:0!important
}

.none-bg-body {
background:#fff
}

.list-partners {
border-bottom:1px solid #D0D5DD;
display:inline-block;
padding-bottom:50px;
text-align:center;
width:100%
}

.list-partners li {
display:inline-block;
margin-bottom:30px;
text-align:center;
width:18%
}

.block-we-do-2 {
padding-left:80px
}

.block-img-we-do .block-chart {
bottom:-100px;
position:absolute;
right:-60px;
width:270px
}

.block-we-do .inner-image .block-image-bottom {
bottom:-80px;
position:absolute;
right:-80px;
width:313px
}

.list-icons .item-icon {
border-bottom:2px solid #E4E7EC;
margin-bottom:40px;
padding:0 0 40px 80px;
position:relative
}

.list-icons .item-icon.none-bd {
border-bottom:0;
margin-bottom:20px;
padding:0 0 20px 80px
}

.list-icons .item-icon .icon-left {
left:0;
position:absolute;
top:0;
width:64px
}

.box-square {
border-radius:16px;
height:455px;
padding:55px 50px 0;
text-align:center;
width:100%
}

.box-square .box-image-inner {
border-radius:16px;
margin-top:30px;
padding:23px 19px
}

.box-square .box-image-inner.bg-color-1 {
background-color:#EBECF4
}

.box-square .box-image-inner.bg-color-2 {
background-color:#CBD1E5
}

.box-gray-100 {
background-color:#F2F4F7;
padding:80px
}

.box-img-user {
margin-top:32px;
min-height:80px;
padding:15px 0 0 100px;
position:relative
}

.box-img-user .img-user {
left:0;
position:absolute;
top:0;
width:80px
}

.box-img-user .img-user img {
border-radius:4px
}

.block-pricing-2 .btn.btn-black {
border:1px solid transparent;
border-radius:6px;
padding:12px 22px;
text-align:center;
width:100%
}

.block-pricing-2 .btn.btn-black:hover {
background-color:#fff;
border:1px solid #101828;
color:#101828
}

.block-pricing-2 .box-pricing-item:hover .text-body-small,.block-pricing-2 .box-pricing-item.active .text-body-small {
color:#F2F4F7!important
}

.block-pricing-2 .box-pricing-item:hover .list-package-feature li,.block-pricing-2 .box-pricing-item.active .list-package-feature li {
background:url(../imgs/template/icons/check-circle-pink.svg) no-repeat left center
}

.block-pricing-2 .box-pricing-item:hover .btn.btn-black,.block-pricing-2 .box-pricing-item.active .btn.btn-black {
background-color:transparent;
background-image:url(../imgs/template/icons/arrow-small-right-w.svg);
background-position:center right 25px;
background-repeat:no-repeat;
border:1px solid #fff;
color:#fff
}

.nav-style-homepage-3 > li:last-child button {
padding-right:0
}

.bg-body-homepage-3::before {
content:"";
height:901px;
left:0;
position:absolute;
top:0;
width:901px;
z-index:-1
}

.header .main-menu li.hr span,.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li.hr span {
background-color:#F2F4F7;
display:block;
height:1px;
margin:5px 0;
width:100%
}

.header .main-menu li a:hover,.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li a:hover {
color:#3F57A7
}

.header .main-menu li ul li,.header .main-menu li:hover > ul li {
width:100%
}

.header .main-menu li:hover > ul,.mobile-menu-active .body-overlay-1 {
opacity:1;
visibility:visible
}

.header .text-link-bd-btom:hover,.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info a:hover,.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info:hover > a {
color:#101828
}

.header.stick,.ptb-10 {
padding:10px 0
}

.header-top.header-top-green,.header-style-2,.block-pricing-2 .box-pricing-item:hover,.block-pricing-2 .box-pricing-item.active {
background-color:#3F57A7
}

.header-style-2.sticky-bar.stick,.header-style-5.sticky-bar.stick {
background-color:#3F57A7;
border-bottom:1px solid #008c99
}

.header-style-2 .burger-icon.burger-icon-white > span::before,.header-style-2 .burger-icon.burger-icon-white > span::after,.header-style-5 .burger-icon.burger-icon-white > span::before,.header-style-5 .burger-icon.burger-icon-white > span::after {
background-color:#E4E7EC
}

.header-style-2 .main-menu > li > a,.header-style-5 .main-menu > li > a,.block-pricing-2 .box-pricing-item:hover *,.block-pricing-2 .box-pricing-item.active * {
color:#fff
}

.header-style-2 .main-menu > li > a:hover,.header-style-5 .main-menu > li > a:hover {
color:#83C5BE
}

.header-style-3,.header-style-3.sticky-bar.stick {
background-color:#FFF3EA
}

.header-style-4,.header-style-4.sticky-bar.stick {
background-color:#BEE1E6
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li.has-children.active,.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li:last-child {
padding-bottom:0
}

.search-form form,.block-video,.block-img-we-do,.block-img-we-do .inner-image,.block-we-do .inner-image {
position:relative
}

.widget-categories li:hover span.count,.bg-green-900 {
background-color:#3F57A7;
color:#fff
}

.footer .footer-bottom .icon-socials:hover,#scrollUp:hover {
transform:translateY(-3px);
transition-duration:.2s
}

.footer .footer-bottom .icon-socials.icon-facebook:hover,.footer .footer-bottom .icon-socials.icon-twitter:hover,.footer .footer-bottom .icon-socials.icon-instagram:hover,.footer .footer-bottom .icon-socials.icon-linkedin:hover {
opacity:.8;
transition-duration:.2s
}

.section-padding-30,.ptb-30 {
padding:30px 0
}

.section-padding-50,.ptb-50 {
padding:50px 0
}

.section-padding-60,.ptb-60 {
padding:60px 0
}

.panel-box,.panel-box img {
max-height:500px
}

.block-img-we-do .block-chart img,.block-we-do .inner-image .block-image-bottom img {
border-radius:16px;
box-shadow:0 47px 65px #151c261a
}

.nav-style-homepage-3 li button.active,.nav-style-homepage-3 li button:hover {
background:none;
color:#3F57A7
}

@media (min-width: 1560px) {
.banner-homepage-3 {
padding:0 0 0 145px
}
}

.banner-homepage-3 {
padding-top:40px
}

.banner-homepage-3 .block-banner {
padding-right:250px
}

.banner-homepage-3 .banner-imgs::before {
bottom:0;
content:"";
height:54px;
left:-70px;
position:absolute;
width:56px;
z-index:0
}

.banner-homepage-3 .banner-imgs .banner-sm1,.banner-homepage-3 .banner-imgs .banner-sm2,.banner-homepage-3 .banner-imgs .banner-sm3 {
left:-86px;
position:absolute;
top:4%;
z-index:12
}

.banner-homepage-3 .banner-imgs .banner-sm2 {
left:-136px;
top:43%
}

.banner-homepage-3 .banner-imgs .banner-sm2 img {
width:144px
}

.banner-homepage-3 .banner-imgs .banner-sm3 {
left:-90px;
top:62%
}

.box-image .btn-play-middle {
background-size:135px;
bottom:0;
height:135px;
left:0;
margin:auto;
position:absolute;
right:0;
top:0;
width:135px
}

.bg-orange-100 {
background-color:#FAEDE3
}

.accordion-item .accordion-button {
background-color:#E4E7EC;
border-radius:4px!important;
border-width:0;
color:#667085;
padding:20px 30px
}

.accordion-button:not(.collapsed) {
background-color:#101828;
color:#fff
}

.accordion-button::after {
background-image:url(../imgs/template/icons/angle-down.svg)
}

.accordion-button:not(.collapsed)::after {
background-image:url(../imgs/template/icons/angle-up-white.svg);
transform:rotate(0deg)
}

.accordion .accordion-item {
margin-bottom:25px
}

.accordion .accordion-item .accordion-header {
line-height:22px
}

.accordion .accordion-item .accordion-collapse {
border-width:0
}

.icon-home {
background:url(../imgs/template/icons/icon-home.svg) no-repeat 0 0;
padding:0 0 0 30px
}

.icon-wave::before {
background:url(../imgs/template/email.png) no-repeat;
content:"";
height:165px;
position:absolute;
right:50px;
top:30px;
width:165px;
z-index:1
}

.icon-wave .row {
position:relative;
z-index:2
}

.text-gray-28 {
color:#98A2B3;
font:700 28px/32px "Chivo",sans-serif
}

.list-icon-3 {
display:table;
max-width:610px;
table-layout:fixed;
width:100%
}

.list-icon-3 li {
background:url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjk5OTkgMEM0LjkzNDYxIDAgMCA0LjkzNDUgMCAxMC45OTk5QzAgMTcuMDY1NCA0LjkzNDYxIDIyIDEwLjk5OTkgMjJDMTcuMDY1MyAyMiAyMS45OTk5IDE3LjA2NTQgMjEuOTk5OSAxMC45OTk5QzIxLjk5OTkgNC45MzQ1IDE3LjA2NTQgMCAxMC45OTk5IDBaTTE3LjMxNjMgOS4xNDE5NkwxMC40MDU2IDE2LjA1MjdDMTAuMTExOCAxNi4zNDY1IDkuNzIxMTUgMTYuNTA4MiA5LjMwNTY0IDE2LjUwODJDOC44OTAxMyAxNi41MDgyIDguNDk5NTIgMTYuMzQ2NSA4LjIwNTY4IDE2LjA1MjdMNC42ODM1OCAxMi41MzA2QzQuMzg5NzQgMTIuMjM2NyA0LjIyNzkgMTEuODQ2MSA0LjIyNzkgMTEuNDMwNkM0LjIyNzkgMTEuMDE1IDQuMzg5NzQgMTAuNjI0NCA0LjY4MzU4IDEwLjMzMDVDNC45NzczMSAxMC4wMzY3IDUuMzY3OTEgOS44NzQ4NSA1Ljc4MzU0IDkuODc0ODVDNi4xOTkwNSA5Ljg3NDg1IDYuNTg5NzcgMTAuMDM2NyA2Ljg4MzUgMTAuMzMwNkw5LjMwNTUyIDEyLjc1MjZMMTUuMTE2MiA2Ljk0MTkzQzE1LjQxIDYuNjQ4MDggMTUuODAwNiA2LjQ4NjM1IDE2LjIxNjEgNi40ODYzNUMxNi42MzE2IDYuNDg2MzUgMTcuMDIyMiA2LjY0ODA4IDE3LjMxNjEgNi45NDE5M0MxNy45MjI4IDcuNTQ4NjcgMTcuOTIyOCA4LjUzNTQ1IDE3LjMxNjMgOS4xNDE5NloiIGZpbGw9IiMwMDZENzciLz4KPC9zdmc+Cg==) no-repeat left center;
color:#667085;
float:left;
margin-bottom:15px;
padding:2px 0 2px 35px;
width:33.33%
}

.box-mw-610 {
max-width:610px
}

.form-newsletter-2 {
background-color:#fff;
border:1px solid #F2F4F7;
border-radius:10px;
box-shadow:14px 14px 36px #99999938;
padding:10px;
position:relative
}

.form-newsletter-2 .input-newsletter {
border:0;
padding:15px 16px;
width:100%
}

.none-bd {
border:0!important
}

.font-bold {
font-weight:700
}

.text-semibold {
color:#98A2B3;
display:inline-block;
font:500 10px/14px "Noto Sans",sans-serif;
vertical-align:text-top
}

.list-category {
display:inline-block;
margin:0;
padding:10px 0 0;
width:100%
}

.list-category li {
border-bottom:1px solid #E4E7EC;
display:inline-block;
font-family:"Chivo",sans-serif;
font-weight:500;
padding:20px 0;
width:100%
}

.list-category li:last-child {
border-bottom:0 solid #E4E7EC
}

.list-category li .number {
background-color:#E4E7EC;
border:1px solid #E4E7EC;
border-radius:50%;
color:#3F57A7;
float:right;
font-size:19px;
height:43px;
line-height:43px;
text-align:center;
width:43px
}

.list-category li a {
color:#667085;
display:inline-block;
font-size:19px;
line-height:30px;
vertical-align:text-top
}

.list-category li:hover a {
color:#3F57A7
}

.list-category li:hover .number {
background-color:#3F57A7;
border:1px solid #3F57A7;
color:#fff
}

.box-bdr-2 {
background-color:#fff;
border:1px solid #E4E7EC;
border-radius:16px;
box-shadow:0 45px 80px #0000000a;
padding:30px 35px
}

.box-green {
background:#3F57A7;
background-size:contain;
padding:70px 64px
}

.box-green .text-desc-white {
color:#fff;
font:18px/32px "Noto Sans",sans-serif
}

.grid-category {
background-color:#fff;
border:1px solid #E4E7EC;
border-radius:16px;
box-shadow:0 45px 80px #0000000a;
margin-bottom:30px;
padding:34px 29px;
position:relative
}

.grid-category .grid-image-left-category {
display:flex;
position:relative
}

.grid-category .grid-image-left-category .category-image {
background-color:#0b7b3f33;
border-radius:50%;
height:60px;
line-height:60px;
margin-right:15px;
text-align:center;
width:60px
}

.grid-category .grid-image-left-category .category-image span {
display:inline-block;
height:60px;
width:60px
}

.grid-category .grid-image-left-category .category-info {
align-items:center;
display:flex;
width:calc(100% - 75px)
}

.grid-category .grid-image-left-category .category-info h3 {
vertical-align:middle
}

.grid-category:hover {
background-color:#3F57A7;
box-shadow:none;
color:#fff
}

.grid-category.grid-category-small {
padding:20px 30px
}

.grid-category.grid-category-small .category-image {
background-color:#0b7b3f33;
border-radius:50%;
height:34px;
line-height:34px;
margin-right:15px;
text-align:center;
width:34px
}

.grid-category.grid-category-small .category-image span {
display:inline-block;
height:34px;
width:34px
}

.grid-category.grid-category-small:hover {
background-color:#3F57A7;
color:#fff
}

.grid-category.grid-category-small:hover .category-image {
background-color:#158E99
}

.box-bg-bottom {
padding:0 50px 20px;
position:relative
}

.box-bg-bottom::before {
background-color:#FFF3EA;
border-radius:30px;
bottom:0;
content:"";
left:0;
position:absolute;
right:0;
top:70px
}

.box-bg-bottom .box-video-homepage6 {
padding:0 35px
}

.box-slider-3 {
background-color:#BEE1E6
}

.pdl-md {
padding-left:10px
}

.block-slider-bottom-banner {
margin-bottom:-50px;
padding-right:190px;
position:relative;
top:-90px;
z-index:12
}

.block-slider-bottom-banner .box-swiper .swiper-button-next {
right:-190px;
top:80%
}

.block-slider-bottom-banner .box-swiper .swiper-button-prev {
right:-105px;
top:80%
}

.swiper-slide-1 {
float:left;
width:30%
}

.swiper-slide-1 .grid-category-style-2 {
box-shadow:none
}

.swiper-slide-2 {
float:left;
width:40%
}

.swiper-slide-2 .grid-category-style-2 {
box-shadow:none;
display:flex
}

.swiper-slide-2 .grid-category-style-2 .grid-image-bottom {
margin-top:0;
width:45%
}

.swiper-slide-2 .grid-category-style-2 .grid-image-left-category {
padding-right:15px;
width:55%
}

.swiper-slide-2 .grid-category-style-2 .grid-image-left-category a {
display:inline-block
}

.swiper-slide-2 .grid-category-style-2 .grid-image-left-category .category-info {
display:inline-block;
width:100%
}

.grid-category-style-2 {
padding:20px 20px 15px
}

.grid-category-style-2 .grid-image-bottom {
margin:20px 0 0
}

.grid-category-style-2 .grid-image-bottom img {
border-radius:16px
}

.pr-mb-70 {
padding-right:70px
}

.list-social-banner {
margin:30px 0 0;
padding:6px 0 0 100px;
position:relative
}

.list-social-banner .social-banner {
display:inline-block;
height:26px;
margin-right:7px;
width:26px
}

.list-social-banner::before {
background-color:#98A2B3;
content:"";
height:1px;
left:0;
position:absolute;
top:50%;
transform:translateY(-50%);
width:85px
}

.block-img-we-do .block-control {
bottom:-40px;
position:absolute;
right:10px;
width:190px
}

.block-img-we-do .block-card {
left:-20px;
position:absolute;
top:40px;
width:250px
}

.img-small {
max-width:80%
}

.list-category-homepage7 .row {
margin:0 -30px
}

.list-category-homepage7 .col-xl-6 {
padding-left:30px;
padding-right:30px
}

.page-homepage7-bg {
height:100%;
position:relative;
top:0;
width:100%;
z-index:-1
}

.list-search-popular {
margin:25px 0 0;
padding:0
}

.list-search-popular li {
display:inline-block;
margin-bottom:15px;
margin-right:15px
}

.list-search-popular li a {
color:#667085;
font-size:14px;
font-weight:700;
line-height:14px;
text-decoration:none
}

.page-homepage8 .header {
background:#fff;
border-bottom:1px solid #E4E7EC;
z-index:12
}

.page-homepage8 .main {
position:relative;
z-index:3
}

.list-icons-round .item-icon {
border-bottom:2px solid #E4E7EC;
margin-bottom:40px;
padding:0 0 40px 90px;
position:relative
}

.list-icons-round .item-icon.none-bd {
border-bottom:0;
margin-bottom:20px;
padding:0 0 20px 90px
}

.list-icons-round .item-icon .icon-left {
border-radius:50%;
box-shadow:14px 14px 36px #99999938;
height:64px;
left:0;
line-height:64px;
position:absolute;
text-align:center;
top:0;
width:64px
}

.bd-bottom {
border-bottom:1px solid #D0D5DD
}

.social-bottom {
padding-top:15px
}

.social-bottom a {
text-decoration:none
}

.social-bottom .icon-socials {
background-size:27px 27px!important;
display:inline-block;
height:27px;
margin-right:5px;
transition-duration:.2s;
width:27px
}

.social-bottom .icon-socials:hover {
transform:translateY(-3px);
transition-duration:.2s
}

.social-bottom .icon-socials.icon-facebook {
background:url(../imgs/template/icons/facebook-gray.svg) no-repeat 0 0;
transition-duration:.2s
}

.social-bottom .icon-socials.icon-facebook:hover {
background:url(../imgs/template/icons/facebook.svg) no-repeat 0 0;
transition-duration:.2s
}

.social-bottom .icon-socials.icon-twitter {
background:url(../imgs/template/icons/twitter-gray.svg) no-repeat 0 0;
transition-duration:.2s
}

.social-bottom .icon-socials.icon-twitter:hover {
background:url(../imgs/template/icons/twitter.svg) no-repeat 0 0;
transition-duration:.2s
}

.social-bottom .icon-socials.icon-instagram {
background:url(../imgs/template/icons/instagram-gray.svg) no-repeat 0 0;
transition-duration:.2s
}

.social-bottom .icon-socials.icon-instagram:hover {
background:url(../imgs/template/icons/instagram.svg) no-repeat 0 0;
transition-duration:.2s
}

.social-bottom .icon-socials.icon-linkedin {
background:url(../imgs/template/icons/linkedin-gray.svg) no-repeat 0 0;
transition-duration:.2s
}

.social-bottom .icon-socials.icon-linkedin:hover {
background:url(../imgs/template/icons/linkedin.svg) no-repeat 0 0;
transition-duration:.2s
}

.list-steps {
display:inline-block;
text-align:center;
width:100%
}

.list-steps li {
display:inline-block;
margin-bottom:10px;
padding-bottom:90px;
padding-right:110px;
position:relative;
vertical-align:top;
width:33%
}

.list-steps li .block-step {
background-color:#FFF3EA;
border-radius:8px;
padding:38px 30px 30px
}

.list-steps li .block-step p.text-body-text {
height:50px;
overflow:hidden
}

.text-inter-lg {
color:D1DAE4;
font:400 18px/28px "Chivo",sans-serif
}

.block-gallery-1 img {
border-radius:8px
}

.block-pl {
padding-left:80px
}

.box-gray-100 .icon-wave::before {
right:-20px;
top:-60px
}

.list-5-col li {
width:20%
}

.list-icons .item-icon.none-bd {
margin-bottom:40px
}

.tick-green {
background:url(../imgs/template/icons/icon-tick-green.svg) no-repeat center;
display:inline-block;
height:22px;
vertical-align:middle;
width:23px
}

.icon-question-2 {
background:url(../imgs/template/icons/icon-question-2.svg) no-repeat center;
display:inline-block;
height:22px;
margin:0 3px;
vertical-align:middle;
width:23px
}

.text-bd-round {
border:1px solid #818B98;
border-radius:81px;
color:#98A2B3;
display:inline-block;
margin:0 3x;
padding:0 6px 1px;
vertical-align:middle
}

.table-pricing {
color:#101828;
font:400 16px/28px "Noto Sans",sans-serif;
text-align:center
}

.table-pricing tr td {
padding:14px 18px
}

.table-pricing tr td:first-child {
text-align:left
}

.table-mw-700 {
min-width:700px
}

.block-tags {
position:absolute;
right:150px;
top:17px
}

.block-tags a {
background-color:#F2F4F7;
border-radius:10px;
display:inline-block;
padding:10px 45px 10px 25px;
position:relative
}

.block-tags a .icon-close {
background:url(../imgs/template/icons/icon-close.svg) no-repeat 0 0;
height:20px;
position:absolute;
right:15px;
top:14px;
width:20px
}

.list-icons-2 {
display:inline-block;
padding:0 0 0 40px
}

.list-icons-2 > li {
background:url(../imgs/template/icons/icon-question.svg) no-repeat 0 0;
float:left;
margin-bottom:20px;
padding-left:50px;
padding-right:40px;
padding-top:6px;
width:50%
}

.list-icons-2 > li ul {
color:#667085;
list-style:disc;
padding-left:20px
}

.images-lists::before {
background-color:#fff;
bottom:0;
content:"";
height:65%;
left:0;
position:absolute;
width:100%;
z-index:1
}

.images-lists img {
border-radius:16px;
position:relative;
z-index:2
}

.images-lists .img-1 {
border-bottom-left-radius:0;
border-top-left-radius:0;
position:relative;
top:-90px
}

.images-lists .img-2 {
bottom:-110px;
position:relative
}

.images-lists .img-4 {
bottom:-150px;
position:relative
}

.images-lists .img-5 {
border-bottom-right-radius:0;
border-top-right-radius:0;
bottom:-10px;
position:relative
}

.link-edit {
position:absolute;
right:15px;
top:50%;
transform:translateY(-50%)
}

.box-login img {
vertical-align:top
}

.box-login .box-login-form {
margin:auto;
max-width:510px;
width:100%
}

.box-login .login-right {
padding-bottom:120px
}

.banner-homepage-3::before,.none-pattern::before {
display:none
}

.banner-homepage-3 .banner-imgs .banner-sm1 img,.banner-homepage-3 .banner-imgs .banner-sm3 img {
width:251px
}

.box-image,.icon-wave,.images-lists .col-lg-2,.images-lists .col-lg-4,.form-group {
position:relative
}

.grid-category .grid-image-left-category .category-image img,.grid-category.grid-category-small .category-image img,.list-icons-round .item-icon .icon-left img {
display:inline-block;
vertical-align:middle
}

.grid-category:hover .category-info h3,.grid-category.grid-category-small:hover .category-info h3 {
color:#fff
}

.swiper-slide-1 .grid-category-style-2:hover,.swiper-slide-2 .grid-category-style-2:hover {
background-color:#fff
}

.swiper-slide-1 .grid-category-style-2:hover .category-info h3,.swiper-slide-2 .grid-category-style-2:hover .category-info h3 {
color:#101828
}

.swiper-slide-1 .grid-image-left-category a,.list-5-col {
display:flex
}

.block-img-we-do .block-control img,.block-img-we-do .block-card img {
border-radius:16px;
box-shadow:0 47px 65px #151c261a
}

.img-bottom,.img-bottom .inner-image {
align-items:flex-end;
display:flex
}

.images-lists,.box-login {
margin-left:auto;
margin-right:auto;
padding-left:var(--bs-gutter-x,0.75rem);
padding-right:var(--bs-gutter-x,0.75rem);
position:relative;
width:100%
}

@media (min-width: 1400px) {
.login-left {
width:55%
}

.login-right {
width:45%
}
}

.slider-news {
padding:0 100px;
position:relative
}

.block-news {
display:inline-block;
padding:0 30px 25px 0;
position:relative;
width:100%
}

.block-news::before {
background-color:#FFF3EA;
border-radius:16px;
bottom:0;
content:"";
left:20px;
position:absolute;
right:0;
top:20px;
z-index:1
}

.block-news .item-news {
background:#fff;
border-radius:16px;
display:inline-block;
line-height:0;
overflow:hidden;
position:relative;
width:100%;
z-index:2
}

.block-news .item-news .img-news {
overflow:hidden
}

.block-news .item-news .img-news img {
height:100%;
max-width:130%
}

.block-news .right-news {
padding:40px 30px 0
}

.block-news .text-desc-fix-h {
max-height:85px;
overflow:hidden
}

.blog-img-user {
margin-top:32px;
min-height:60px;
padding:7px 0 0 70px;
position:relative
}

.blog-img-user .img-user-round {
left:0;
position:absolute;
top:0;
width:56px
}

.blog-img-user .img-user-round img {
border-radius:50%;
height:56px;
width:56px
}

.blog-img-user .text-heading-6 {
margin-bottom:3px
}

.card-list-style-1 .blog-img-user {
margin-top:15px;
padding:0 0 0 51px
}

.card-list-style-1 .blog-img-user .img-user-round {
left:0;
position:absolute;
top:0;
width:41px
}

.card-list-style-1 .blog-img-user .img-user-round img {
border-radius:50%;
height:41px;
width:41px
}

.text-summary {
color:#667085;
font:700 18px/32px "Noto Sans",sans-serif
}

.single-detail p {
color:#667085;
font:400 18px/32px "Noto Sans",sans-serif;
margin-bottom:15px
}

.single-detail h1,.single-detail h2,.single-detail h3,.single-detail h4,.single-detail h5,.single-detail h6 {
color:#101828;
font-weight:700;
margin:0 0 20px
}

.single-detail h6 {
font-size:18px
}

.social-sticky {
position:sticky;
top:70px
}

.box-comments {
display:inline-block;
padding:60px 0 0;
width:100%
}

.box-comments ul li .item-comment {
display:inline-block;
margin-bottom:40px;
padding:0 0 0 250px;
position:relative;
width:100%
}

.box-comments ul li .item-comment .blog-img-user {
left:0;
margin-top:0;
position:absolute;
top:0
}

.box-comments ul li ul {
padding-left:56px
}

.box-comments .text-comment {
background-color:#F2F4F7;
border-radius:0 16px 16px 16px;
color:#101828;
padding:20px
}

.bdr-10 {
border-radius:10px
}

.box-quote {
background-color:#3F57A7;
border-radius:20px;
color:#fff;
margin:50px 0;
padding:45px
}

.box-quote .text-quote {
background:url(../imgs/template/icons/left-quote.svg) no-repeat top center;
font:400 32px/48px "Chivo",sans-serif;
padding:75px 0 0;
text-align:center
}

.box-user {
margin:35px 0 20px;
position:relative;
text-align:center
}

.box-user .img-user {
display:inline-block;
margin-right:10px;
vertical-align:middle;
width:55px
}

.box-user .img-user img {
border-radius:50%
}

.text-top {
vertical-align:top
}

.share-social {
background-position:center;
background-repeat:no-repeat;
display:inline-block;
height:30px;
width:30px
}

.category-grid-3 {
border:1px solid #E4E7EC;
border-radius:16px;
margin-bottom:30px;
padding:25px 5px;
text-align:center
}

.category-grid-3:hover {
border-color:#BEE1E6;
box-shadow:0 45px 80px #0000000a
}

.category-grid-3 .category-img {
margin:0 0 15px
}

.filters-products {
background-color:#F2F4F7;
border-radius:8px;
padding:20px
}

.fitler-info {
text-align:right;
width:50%
}

.bdr-18 {
border-radius:18px
}

.box-green-2 .block-1 {
left:50%;
position:absolute;
top:10%;
width:210px
}

.box-green-2 .block-2 {
left:43%;
position:absolute;
top:72%;
width:240px
}

.dropdown-sort {
display:inline-block;
line-height:21px;
vertical-align:middle
}

.dropdown-sort .dropdown-toggle {
color:#3F57A7;
font-size:14px;
font-weight:400;
outline:none;
padding:0
}

.dropdown-sort .dropdown-toggle i {
color:#88929b;
float:right;
font-size:20px;
margin:0 7px 0 0
}

.dropdown-sort .dropdown-toggle::after {
border:0
}

.dropdown .dropdown-menu.show {
background-color:#fff;
border:thin solid #ececec;
border-radius:10px;
box-shadow:0 9px 26px 0 #1f1f330f;
padding:0
}

.dropdown .dropdown-menu.show .dropdown-item {
font-size:14px;
padding:10px 20px
}

.dropdown .dropdown-menu.show .dropdown-item.active {
background-color:#AFEAE4;
color:#3F57A7;
text-decoration:none
}

.paginations {
margin:30px 0 50px
}

.paginations .pager {
padding:0
}

.paginations .pager li a {
background-color:#E4E7EC;
border-radius:50%;
color:#667085;
display:block;
font:700 18px/30px "Chivo",sans-serif;
height:50px;
margin:0 5px;
padding:10px 8px;
position:relative;
text-align:center;
text-decoration:none;
width:50px
}

.paginations .pager li a:hover {
background-color:#3F57A7;
color:#fff
}

.product-item-2:hover {
border-color:#BEE1E6
}

.sidebar {
border:1px solid #E4E7EC;
border-radius:16px;
margin-bottom:50px;
padding:25px
}

.sidebar .widget-title {
border-bottom:1px solid #E4E7EC;
padding-bottom:20px
}

.list-type {
display:inline-block;
list-style:none;
margin:20px 0;
padding:0;
width:100%
}

.list-type li {
margin:0 0 20px
}

.list-type li label {
line-height:20px;
margin-bottom:0;
width:100%
}

.list-type li label input {
float:left;
margin:8px 10px 0 0
}

.text-heading-7 {
color:#101828;
font:700 14px/18px "Noto Sans",sans-serif
}

.product-item-4 {
border:0;
box-shadow:none!important;
padding:0
}

.product-item-4 .product-image {
float:left;
height:auto;
line-height:20px;
min-height:auto;
width:65px
}

.product-item-4 .product-image img {
max-width:100%
}

.product-item-4 .product-info {
display:block;
padding:0
}

.product-item-4 .product-info .box-prices {
width:100%
}

.list-products-sidebar {
list-style:none;
margin:0;
padding:15px 0 0
}

.list-products-sidebar li {
display:inline-block;
padding:0;
width:100%
}

.sidebar-gray {
background-color:#F2F4F7;
border:0
}

.btn-green-small {
padding:12px 28px
}

.btn-green-small:hover {
background-image:none!important
}

.circle-round {
background-color:#3F57A7;
border-radius:50%;
display:inline-block;
height:20px;
margin-right:7px;
vertical-align:middle;
width:20px
}

.color-danger {
background-color:#EF476F
}

.color-success {
background-color:#06D6A0
}

.color-primary {
background-color:#508FDA
}

.color-black {
background-color:#101828
}

.color-warning {
background-color:#FFD166
}

.btn-filter:hover {
background-color:#D1ECFD
}

.dr-ltr {
direction:ltr
}

.dr-rtl {
direction:rtl
}

.banner-ads .text-head-ads {
color:#3F57A7;
font:700 39px/42px "Noto Sans",sans-serif
}

.banner-ads .desc-ads {
color:#667085;
font:16px/20px "Noto Sans",sans-serif
}

.banner-ads .box-banner-ads {
display:table;
margin:auto;
max-width:510px
}

.table-of-content ul li {
padding:5px 0
}

.table-of-content ul li a {
color:#667085;
transition-duration:.2s
}

.table-of-content ul li a:hover {
color:#3F57A7;
padding-left:5px;
transition-duration:.2s
}

.nav-breadcrums {
padding:10px 0;
position:relative;
width:100%
}

.nav-breadcrums .breadcrumb ul li a {
color:#667085;
font-size:14px;
line-height:19px
}

.detail-gallery {
position:relative
}

.product-image-slider {
align-items:center;
background-color:#fff;
border:1px solid #ececec;
border-radius:15px;
display:flex;
line-height:710px;
margin-bottom:30px;
min-height:710px;
overflow:hidden
}

.product-image-slider .slick-slide img {
display:block;
margin:auto;
max-width:99%
}

.slider-nav-thumbnails {
display:block;
left:0;
position:relative;
top:-30px;
width:178px
}

.slider-nav-thumbnails .slick-slide {
border:1px solid #E4E7EC;
border-radius:16px;
height:200px;
line-height:200px;
margin-top:30px;
text-align:center
}

.slider-nav-thumbnails .slick-slide img {
display:inline-block;
height:auto;
max-height:90%;
max-width:90%;
vertical-align:middle
}

.box-rate-product {
text-align:right;
width:50%
}

.box-rate-product .box-rating span {
display:inline-block;
margin-right:5px
}

.tag-category {
color:#667085;
font:700 12px/16px "Noto Sans",sans-serif
}

.box-price {
display:inline-block;
margin:30px 0 20px;
width:100%
}

.box-price .price {
color:#004B52;
font:700 35px/38px "Chivo",sans-serif;
margin-right:15px
}

.box-price .price-old {
color:#667085;
font:700 28px/32px "Chivo",sans-serif;
margin-right:15px;
text-decoration:line-through
}

.text-stock {
color:#667085;
font:400 16px/28px "Noto Sans",sans-serif
}

.font-bold {
font-weight:700!important
}

.product-description {
display:table;
margin:auto;
max-width:900px;
padding:0;
table-layout:fixed;
width:100%
}

.product-description .tab-content ul {
color:#667085;
list-style:disc;
margin:20px 0;
padding:0 0 0 20px
}

.product-description .tab-content ul li {
padding-left:5px
}

.product-description .tab-content .contact-infor {
color:#253D4E;
font-size:15px;
list-style:none;
padding:0
}

.btn.btn-tab {
background-color:#fff;
border-color:#E4E7EC;
color:#667085;
margin-bottom:15px;
margin-right:20px;
padding:15px 32px
}

.item-cat {
display:inline-block;
margin-right:20px
}

.galleries {
padding:0 0 0 210px;
position:relative
}

.slick-initialized .slick-slide {
text-align:center
}

.product-info {
display:inline-block;
padding:0 0 0 20px
}

.title-question {
color:#667085;
font-size:18px;
line-height:28px
}

.progress span {
background:#fff;
line-height:16px;
padding-right:10px;
width:45px
}

.progress + .progress {
margin-top:1rem
}

.progress-bar {
background-color:#3F57A7
}

.comments-area .comment-list:last-child {
padding-bottom:0
}

.comments-area .comment-list .single-comment {
border:1px solid #f2f2f2;
border-radius:15px;
margin:0 0 15px;
padding:20px;
transition:.2s
}

.comments-area .comment-list .single-comment:not(:last-child) {
border-bottom:1px solid #ececec
}

.comments-area .comment-list .single-comment img {
max-width:80px;
min-width:80px
}

.comments-area .comment-list .single-comment .reply {
color:#06D6A0;
opacity:0;
transition:.2s
}

.comments-area .comment-list .single-comment:hover .reply {
opacity:1;
transition:.2s
}

.comments-area .thumb {
margin-right:20px
}

.comments-area .thumb img {
border-radius:50%;
width:70px
}

.justify-content-between {
justify-content:space-between!important
}

.d-flex {
display:flex!important
}

.comments-area .text-muted {
color:#667085!important
}

.comments-area .text-brand {
color:#3F57A7!important;
line-height:1.2
}

.comments-area .font-xs {
font-size:13px
}

.contact-infor li {
color:#253D4E
}

.contact-infor li:not(:last-child) {
margin-bottom:10px
}

.contact-infor li img {
margin-right:8px;
max-width:16px
}

.tab-pane .table {
border-collapse:collapse;
border-spacing:0;
font-size:16px;
margin-bottom:1.5rem;
vertical-align:middle;
width:100%
}

.box-countdown {
border-bottom:1px solid #E4E7EC;
display:flex;
margin-bottom:45px;
padding-bottom:30px
}

.text-hurry {
color:#004B52;
font:700 18px/22px "Chivo",sans-serif
}

.process-bar-line {
background-color:#E4E7EC;
border-radius:10px;
display:inline-block;
height:11px;
margin-top:10px;
position:relative;
width:100%
}

.box-processbar {
width:35%
}

.process-bar-inner {
background-color:#E4E7EC;
border-radius:10px;
display:inline-block;
height:11px;
position:relative;
vertical-align:top;
width:100%
}

.detail-extralink .detail-qty {
background:#fff;
border:1px solid #AFEAE4!important;
border-radius:5px;
color:#06D6A0;
display:inline-block;
font-size:16px;
font-weight:700;
margin:0 6px 15px 0;
max-width:110px;
padding:11px 20px 11px 30px;
position:relative;
vertical-align:top;
width:100%
}

.detail-extralink > div {
display:inline-block;
vertical-align:top
}

.detail-qty > a {
color:#06D6A0;
font-size:16px;
height:20px;
position:absolute;
right:8px;
width:20px
}

input.qty-val {
border:0;
border-radius:0;
color:#004B52;
font-size:20px;
font-weight:700;
height:unset;
padding:0!important;
width:100%
}

.product-extra-link2 .button.button-add-to-cart {
background-color:#AFEAE4;
border:0;
border-radius:5px;
color:#3F57A7;
display:inline-block;
font-size:16px;
font-weight:700;
height:50px;
line-height:50px;
padding:0 20px;
position:relative
}

.product-extra-link2 .button.button-add-to-cart:hover {
background-color:#fff;
border:1px solid #AFEAE4
}

.product-extra-link2 .button.button-add-to-cart img {
float:left;
margin:17px 10px 0 0
}

.product-extra-link2 a {
background:#fff none repeat scroll 0 0;
border:1px solid #AFEAE4;
border-radius:5px;
color:#333;
display:inline-block;
height:50px;
line-height:55px;
margin:0 5px;
position:relative;
text-align:center;
transition:all .5s ease-out 0s;
vertical-align:top;
width:50px
}

.product-extra-link2 a:hover {
background-color:#AFEAE4
}

.product-extra-link2 a i {
font-size:18px;
opacity:.6
}

.box-count {
display:flex;
width:65%
}

.deals-countdown {
display:flex;
position:relative;
right:-20px;
width:100%
}

.countdown-section {
color:#667085;
padding:0 20px 0 0;
position:relative;
width:25%
}

.countdown-section:first-child {
margin-left:20px
}

.countdown-amount {
border:1px solid #E4E7EC;
border-radius:4px;
color:#004B52;
display:inline-block;
font:700 22px/24px "Chivo",sans-serif;
padding:20px 10px;
text-align:center;
width:100%
}

.countdown-period {
display:block;
font:600 10px/14px "Noto Sans",sans-serif;
margin-top:4px;
text-align:center
}

.swiper-button-next-5::after,.swiper-button-prev-5::after,.countdown-section:last-child::before {
display:none
}

.single-detail img,.bdr-16 {
border-radius:16px
}

.box-comments ul li,.nav-breadcrums .breadcrumb ul {
display:inline-block;
width:100%
}

.number-info,.box-category-product {
width:50%
}

.paginations .pager li,.d-inline-block {
display:inline-block;
vertical-align:middle
}

.list-products-sidebar li:last-child .product-item-4,.nav-breadcrums .breadcrumb {
margin-bottom:0
}

.table-of-content h6,.comments-area .font-heading,strong {
font-weight:700
}

.nav-breadcrums .breadcrumb ul li a:hover,.comments-area .comment-list .single-comment .reply:hover {
color:#3F57A7
}

.slider-nav-thumbnails .slick-slide:hover,.slider-nav-thumbnails .slick-slide.slick-current {
border:1px solid #AFEAE4;
cursor:pointer
}

.btn.btn-tab:hover,.btn.btn-tab.active {
background-color:#fff;
border-color:#AFEAE4;
color:#004B52!important
}

.comments-area h6,.vendor-logo h6 {
color:#253D4E;
font-size:16px;
font-weight:700;
line-height:1.2
}

.tab-pane .table td,.tab-pane .table th {
border:1px solid #ececec;
color:#667085;
font-weight:400;
padding:10px 20px;
vertical-align:middle
}

.zoomContainer,.zoomWindow {
z-index:99
}

@media only screen and (max-width: 1368px) {
.slider-nav-thumbnails {
width:135px
}

.slider-nav-thumbnails .slick-next {
left:20px
}

.slider-nav-thumbnails .slick-prev {
right:20px
}

.galleries {
padding:0 0 0 165px
}
}

@media only screen and (max-width: 992px) {
.product-info {
padding-left:0
}
}

@media only screen and (max-width: 768px) {
.galleries {
padding:0
}

.slider-nav-thumbnails {
margin-left:-10px;
margin-right:-10px;
position:relative;
width:auto
}

.slider-nav-thumbnails .slick-slide {
margin-left:10px;
margin-right:10px
}

.slider-nav-thumbnails .slick-prev {
bottom:auto;
left:-11px;
margin-top:-10px;
right:auto;
top:50%;
transform:rotateZ(-90deg)
}

.slider-nav-thumbnails .slick-next {
bottom:auto;
left:auto;
margin-top:-10px;
right:-10px;
top:50%;
transform:rotateZ(-90deg)
}

.deals-countdown {
right:-15px
}

.product-image-slider {
line-height:auto;
min-height:auto
}

.btn.btn-tab {
font-size:13px;
padding:11px 12px!important
}

.box-green-2 {
background-image:none
}

.box-green-2 .block-1,.box-green-2 .block-2 {
display:none
}
}

@media only screen and (max-width: 480px) {
.detail-extralink .detail-qty {
max-width:90px
}

.box-countdown {
display:inline-block;
width:100%
}

.box-processbar {
width:100%
}

.box-count {
margin-top:20px;
width:100%
}

.countdown-section:first-child {
margin-left:0
}

.deals-countdown {
right:-5px
}

.slider-nav-thumbnails .slick-next {
right:0
}

.slider-nav-thumbnails .slick-prev {
left:0
}

.slider-nav-thumbnails .slick-slide {
height:130px;
line-height:130px
}
}

@media only screen and (max-width: 400px) {
.product-item-1 .product-info {
padding:20px 0 20px 110px
}

.product-item-1 .product-image {
width:90px
}
}

@media (min-width: 1400px) {
.block-we-do {
padding-left:40px
}

.block-img-we-do {
padding-right:30px
}
}

@media (max-width: 1399.98px) {
.custom-class {
display:block
}

.banner-hero.banner-2 .block-2 {
right:-10px
}
}

@media (max-width: 1199.98px) {
.custom-class {
display:block
}

.box-newsletter .block-chart {
bottom:-60px;
height:164px;
left:10px;
max-width:215px
}

.list-steps li {
padding-right:30px
}

.block-pl {
padding-left:30px
}

.block-pl .mt-20 {
margin-top:0!important
}

.block-news .right-news {
padding:40px 30px
}

.block-pricing-3 .block-pricing-left {
margin-bottom:30px
}

.list-steps li.icon-asset1::before,.list-steps li.icon-asset2::before,.list-steps li.icon-asset4::before {
height:30px;
right:0;
width:30px
}
}

@media (max-width: 991.98px) {
.list-partners li {
width:28%
}

.block-video .btn-play-video {
left:50%;
margin-left:-68px
}

.text-display-2 {
font-size:64px;
line-height:70px
}

.pattern-white {
padding-left:30px;
padding-right:30px
}

.card-grid-style-2 {
padding:24px 20px
}

.pt-xs-30 {
padding-top:30px
}

.block-img-we-do {
margin-bottom:30px
}

.banner-hero {
max-width:100%;
padding:60px 0
}

.banner-hero.banner-3 {
padding-top:50px
}

.header .main-header .header-right {
max-width:265px
}

.header .main-header .header-right .block-signin .btn.btn-default {
font-size:16px;
padding:12px 55px 12px 20px
}

.box-newsletter .img-newsletter {
bottom:0;
max-width:100%;
position:relative
}

.box-newsletter {
padding:37px 30px 36px
}

.box-newsletter .block-chart {
bottom:-10px;
left:0;
max-width:225px
}

.text-heading-1 {
font-size:46px;
line-height:52px
}

.block-img-we-do .block-chart {
bottom:-30px;
right:-10px
}

.block-we-do .inner-image {
position:relative
}

.block-we-do .inner-image .block-image-bottom {
bottom:-80px;
right:-30px
}

.block-we-do-2 {
padding-left:15px
}

.box-square {
height:auto;
margin-bottom:30px
}

.box-gray-100 {
padding:40px
}

.banner-hero.bg-about-1 {
padding-top:60px
}

.list-steps li {
padding-bottom:15px;
padding-left:8px;
padding-right:8px;
width:49%
}

.list-steps li::before {
display:none
}

.block-pl {
padding-left:0
}

.block-pl .mt-20 {
margin-top:20px!important
}

.text-display-3 {
font-size:45px;
line-height:52px
}

.mb-mobile {
margin-bottom:0!important
}

.list-5-col {
display:inline-block;
text-align:center;
width:100%
}

.list-5-col li {
display:inline-block;
margin-bottom:30px;
vertical-align:top;
width:33%
}

.page-service-2 {
background-size:auto 650px
}

.banner-hero.bg-service-2 {
padding-top:20px
}

.images-lists img {
margin-bottom:25px
}

.images-lists .img-1 {
top:-40px
}

.images-lists .img-2 {
bottom:-50px
}

.images-lists .img-4 {
bottom:-60px
}

.images-lists .img-5 {
bottom:0
}

.box-login .login-right {
padding-bottom:90px
}

.box-login .login-left {
min-height:550px
}

.page-blog-1 {
background-size:auto 550px
}

.mt-md-0 {
margin-top:0!important
}

.pl-lg-15 {
padding-left:15px!important
}

.header-right {
padding-right:50px
}

h2.section-title {
font-size:48px
}

.nav.nav-right {
margin-top:30px
}

.mt-lg-30 {
margin-top:30px!important
}

.panel-box,.panel-box img {
max-height:unset
}

.burger-icon,.sticky-bar.stick .burger-icon {
top:25px
}

.footer .width-20,.footer .width-16 {
width:50%
}
}

@media (max-width: 767.98px) {
.icon-wave::before {
content:none
}

.box-optimized {
padding:40px
}

.text-display-2 {
font-size:50px;
line-height:58px
}

.block-video .btn-paly-video {
left:0;
margin:auto;
right:0;
top:50%;
transform:translateY(-50%)
}

.pattern-white {
padding-left:15px;
padding-right:15px
}

.btn.btn-select {
font-size:14px;
line-height:18px;
padding:10px 20px
}

.block-img-we-do .block-chart {
right:-5px
}

.block-we-do .inner-image .block-image-bottom {
right:-20px
}

.list-partners li {
width:24%
}

.text-mb-center {
text-align:center!important
}

.page-faqs-1 {
background-size:auto 560px
}

.box-login .login-left {
min-height:450px
}

.box-comments ul li .item-comment {
padding:65px 0 0
}

.swiper-button-next-5,.swiper-button-prev-5 {
display:none!important
}

.section-title-large {
font-size:42px
}

.header-right {
display:none
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top {
border-bottom:1px solid #101828;
padding:15px 30px 13px
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top .mobile-header-logo a img {
width:140px
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area {
padding:30px
}

.nav.nav-right {
margin-top:30px
}

.box-head-single h3 {
font-size:36px;
line-height:1.2
}

.social-share {
margin-top:25px
}

.social-share .btn-sm {
padding:10px 15px;
vertical-align:middle
}

.job-overview {
padding:25px!important
}

.text-mb-sm-20 {
display:block;
margin:0 0 20px!important
}

.footer .width-20,.footer .width-16 {
width:100%
}

.burger-icon,.sticky-bar.stick .burger-icon {
top:17px
}

.slider-news,.banner-hero .block-banner {
padding:0
}
}

@media (max-width: 575.98px) {
.text-display-2 {
font-size:40px;
line-height:48px
}

.text-heading-1 {
font-size:35px;
line-height:44px
}

.text-heading-2 {
font-size:28px;
line-height:32px
}

.text-heading-4 {
font-size:20px;
line-height:26px
}

.text-body-lead-large {
font-size:20px;
line-height:28px
}

.box-optimized {
padding:20px
}

.block-we-do .inner-image .block-image-bottom {
bottom:-50px;
right:-5px
}

.list-partners li {
padding:0;
width:32%
}

.list-partners li a {
padding:10px 15px
}

.text-display-3 {
font-size:35px;
line-height:42px
}

.banner-2 .pr-40 {
padding-right:0!important
}

.banner-2 .ml-40 {
margin-left:10px!important;
margin-top:15px
}

.bg-service-1 .ml-40 {
margin-left:10px!important
}

.btn-mb {
margin-left:0!important
}

.box-image .btn-play-middle {
background-size:70px;
height:70px;
width:70px
}

.btn {
padding:15px 22px
}

.btn.icon-arrow-left {
padding-left:50px
}

.icon-arrow-right-white,.icon-arrow-right {
background-position:center right 20px;
padding-right:45px
}

.list-icons .item-icon.none-bd {
margin-bottom:20px
}

.page-service-2 {
background-size:auto 550px
}

.images-lists img {
margin-bottom:25px
}

.images-lists .img-1 {
top:auto
}

.box-login .login-left {
min-height:400px
}

.box-quote {
padding:25px 15px
}

.box-quote .text-quote {
font-size:18px;
line-height:32px
}

.text-heading-5 {
font-size:18px
}

.text-heading-3 {
font-size:25px;
line-height:30px
}

.single-detail p {
font-size:16px;
line-height:30px
}

.form-comment .text-end {
text-align:center!important
}

.header.sticky-bar .col-lg-7 .burger-icon {
right:15px
}

.banner-hero.hero-1 h1 {
font-size:38px
}

.banner-hero .block-banner .form-find {
margin-top:40px!important;
padding:15px
}

.banner-hero .block-banner .form-find form button {
margin-top:15px
}

.swiper-button-next,.swiper-button-prev {
display:none
}

h2.section-title {
font-size:32px;
line-height:48px
}

.mt-sm-15 {
margin-top:15px!important
}

.pt-sm-15 {
padding-top:15px
}

.swiper-group-6 {
max-height:140px
}

.box-swiper .swiper-container .item-logo {
box-shadow:none
}

.box-newsletter {
padding:50px 15px
}

.box-newsletter .text-md-newsletter {
font-size:22px;
line-height:28px
}

.box-newsletter .text-lg-newsletter {
font-size:32px
}

.footer .col-xs-6 {
width:50%
}

.footer ul.mt-40 {
margin-bottom:20px!important;
margin-top:15px!important
}

.footer .footer-social {
margin-left:-10px;
margin-top:15px
}

.archive-header h3 {
font-size:32px;
line-height:1.2
}

.header,.header.stick {
padding:15px 0
}

.images-lists .img-2,.images-lists .img-4,.images-lists .img-5 {
bottom:auto
}

.section-box.mt-70,.section-box.mt-80,.section-box.mt-100,.section-box.mt-40,.mt-sm-30 {
margin-top:30px!important
}

.banner-hero .block-banner .form-find form,.box-newsletter form {
display:block
}

.banner-hero .block-banner .form-find form input,.nav-right li {
margin-bottom:15px
}
}

@media (max-width: 449.98px) {
.list-steps li {
padding-left:0;
padding-right:0;
width:100%
}

.list-5-col li {
width:49%
}

.page-service-2 {
background-size:auto 650px
}

.box-signup .box-form-signup {
margin-bottom:120px!important;
padding:20px
}

.box-line-throught {
padding:0 30px
}

.box-login .login-left {
min-height:300px
}

.box-login-form .box-signup .box-form-signup {
margin-bottom:0!important
}

.tag-mb .tag-1 {
font-size:12px;
line-height:14px;
padding:10px 15px
}

.block-pricing-3 {
padding:15px
}

.block-pricing-3 .block-pricing-left {
margin-bottom:30px;
padding:40px 20px
}

.block-pricing-3 .block-price-item {
padding-left:70px;
padding-right:10px
}

.block-pricing-3 .block-price-item .checkbox {
left:20px
}

.block-pricing-3 .block-price-item .box-info-price {
margin-top:10px;
position:relative;
right:auto;
top:auto;
transform:none
}

.box-line-throught::before,.box-line-throught::after {
width:26px
}
}

@media (min-width: 1400px) {
.container.wide {
max-width:1544px
}

.box-login-form .box-signup {
margin:0
}
}

@media (min-width: 1200px) {
.mb-lg-100 {
margin-bottom:100px
}

.mt-lg-100 {
margin-top:100px
}
}

@media screen and (min-width: 1200px) and (max-width: 1440px) {
.header-right {
display:none
}
}