* {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
  }

  .q_wrapper {
    max-width: 100%;
    margin: auto;
  }
  .q_item {
    border-style: dotted;
    border-color: #fff;
    border-width: 1px;
  }
  .q_title {
    padding: 32px;
    background: #058ED9;
    color: whitesmoke;
    font-weight: 700;
    cursor: pointer;
    font-size: 20px;
    border-radius: 10px;
    position: relative;
    border-style: solid;
  }
  .q_title > label {
    color: #fff !important;
  }
  .q_title span.arrow {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
    width: 30px;
    height: 30px;
    background-size: contain;
    position: absolute;
    right: 20px;
    color: #fff;
    top: 0;
    margin: auto;
    bottom: 0;
    transition: all 0.5s;
  }
  .q_title.active span.arrow {
    transform: rotate(180deg);
  }
  .q_details {
    font-size: 18px;
    padding: 15px;
    background: white;
    display: none;
    color:#fff;
    border-radius: 10px;
  
  }
  .q_item:first-child .q_details {
    display: block;
  }
  .q_details_pair{
    display: inline;
    
  }
  .q_details_pair:first-child{
    margin-right: 10px;
  }
  .q_details_pair:nth-child(2){
    margin-left: 10px;
  }
  .completed{
    background-color: #77CBB9;
  }
  .hide{
    display: none;
    position: relative;
    margin-left: 1rem;
  }
  form > h2{
    color: black !important;
    font-size: medium;
  }
  label{
    font-size: medium;
    color: black !important;

  }
  .text-heading-3{
    color: black !important;
  }